import { css, SerializedStyles } from "@emotion/core";
import { MPX, Colors } from "./themes";

export const TOP_BAR_HEIGHT = MPX * 17.5;

export const TITLE_HEIGHT = MPX * 22;
export const TITLE_PADDING_BOTTOM = 8 * MPX;
export const contentWrapperPaddingCss = css`
  padding: ${6 * MPX}px !important;
`;

export const GRID_SPACING_CONSTANT = 3;

export const fileContainerCss: SerializedStyles = css`
  height: calc(
    100% - ${Math.round(TITLE_HEIGHT + TITLE_PADDING_BOTTOM)}px
  ) !important;
`;

export const imgHelperCss: SerializedStyles = css`
  max-width: ${MPX * 25}px;
  max-height: ${MPX * 25}px;
`;

export const imgHelperWrapCss: SerializedStyles = css`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const lineCss: SerializedStyles = css`
  height: ${MPX * 1.25}px;
  width: ${MPX * 18.5}px;
  background-color: ${Colors.secondary};
`;
