/** @jsx jsx */

import { FC } from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";
import { Link, useLocation } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import { Trans } from "@lingui/react";
import { menuItems } from "../../router";
import { Colors, MPX } from "../../styles/themes";

interface LeftMenuProps {
  closeDrawer: () => void;
}

export const LeftMenu: FC<LeftMenuProps> = ({ closeDrawer }: LeftMenuProps) => {
  const currentRoute = useLocation().pathname;

  const getFirstPathPart = (fullpath: string): string => {
    return `/${fullpath.split("/")[1]}`;
  };

  const firstPart = getFirstPathPart(currentRoute);

  return (
    <div css={wrapperCss}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        css={containerCss}
      >
        {menuItems.map((item) => (
          <Button
            key={item.name}
            css={itemWrapCss(firstPart === item.path.toLocaleLowerCase())}
          >
            <Link to={item.path} css={linkCss} onClick={closeDrawer}>
              <img
                src={
                  firstPart === item.path.toLocaleLowerCase()
                    ? item.iconActive
                    : item.icon
                }
                css={imgCss}
                alt={item.name}
              />
              <span css={textCss(firstPart === item.path.toLocaleLowerCase())}>
                <Trans id={item.name} />
              </span>
            </Link>
          </Button>
        ))}
      </Grid>
    </div>
  );
};
const wrapperCss = css`
  background-color: ${Colors.tabBar};
  height: 100%;
  min-width: ${MPX * 60}px;
`;

const containerCss: SerializedStyles = css`
  flex: 1;
  padding-top: ${MPX * 6}px !important;
`;
const itemWrapCss = (isActive: boolean): SerializedStyles => css`
  border-radius: 0px !important;
  padding: 0 !important;
  width: 100%;
  background-color: ${Colors.tabBar};
  position: relative;
  text-transform: capitalize !important;

  ${isActive &&
  ` &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: ${MPX * 1.25}px;
    background-color: ${Colors.dark};
  }`}
`;
const linkCss: SerializedStyles = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  width: 100%;
  padding: ${MPX * 6}px !important;
`;
const imgCss: SerializedStyles = css`
  width: ${MPX * 6}px;
  height: ${MPX * 6}px;
`;
const textCss = (isActive: boolean): SerializedStyles => css`
  margin-left: ${MPX * 8}px;
  font-size: ${MPX * 5}px;
  font-weight: ${isActive ? 700 : 400};
  color: ${isActive ? Colors.dark : Colors.darkGrey};
`;
