const clientId = process.env.REACT_APP_CLIENT_ID;
const redirectUri = process.env.REACT_APP_REDIRECTION_URL;
const oauthPath = process.env.REACT_APP_OAUTH;
const ocsApi = process.env.REACT_APP_WEB_API_URL;

export const redirectUrlForHref = `${oauthPath}?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`;
export const redirectUrlForLogoutHref = `${ocsApi}/apps/rsam/api/v1/logout?redirect_url=${encodeURIComponent(
  `${window.location.origin}/tools`
)}`;

export const notificationDownloadTimer = 9000;

export const dateFormat = "dd-MM-yyyy";
export const longDateFormat = "dd-MM-yyyy-HH_mm";
export const spacedFormat = "dd MMM yyyy";
