import prettyBytes from "pretty-bytes";
import * as Sentry from "@sentry/browser";
import { getJSON } from "js-cookie";
import { encode as encodeBase64 } from "js-base64";
import { format, Locale } from "date-fns";
import { FileStat } from "webdav/web";
import { File, User } from "../interfaces/file";
import { communication } from "./apiCall";
import { listViewMenuItems } from "../interfaces/listViewMenuItems";
import { FileType, WebDavFileType } from "../enums/file";
import { fileMapper, icons } from "../constants/fileMapper";
import { MimeType, typesToOpen } from "../enums/mimeType";
import { getDataPathFromFilename, checkSlashAtEnd } from "./pathHelper";
import { FileInfo } from "../interfaces/fileInfo";
import { getUsername } from "./commons";
import { spacedFormat } from "../constants/common";
import { TOKEN_COOKIE_NAME } from "../constants/cookie";

export const isPrivateFile = (file: File): boolean => {
  return ["presentation.json", "meta.json"].includes(file.basename);
};

export const getImage = (file: File): string | undefined => {
  return file.type === "file" ? file.downloadUrl : file.preview;
};

/* eslint-disable global-require */
export const getImageSource = (image: string | undefined): any => {
  if (!image) {
    return require(`../../assets/images/folder.png`);
  }
  return image;
};
/* eslint-enable global-require */

export const getThumbnail = async (item: File): Promise<string> => {
  try {
    const filename = checkSlashAtEnd(item.filename);
    if (
      item.info &&
      item.info.presentation &&
      item.info.presentation.slides.length
    ) {
      const firstSlide = item.info.presentation.slides.find(
        (s: any) => s.id === 1
      );

      if (firstSlide) {
        const file = await getFile(`${filename}${firstSlide.thumbnail}`);
        return file?.downloadUrl || icons.directory;
      }
    }
    return icons.directory;
  } catch (error) {
    return icons.directory;
  }
};

export const getItemIcon = async (item: File): Promise<string> => {
  const mappedItem = item.mime && fileMapper[item.mime];
  if (mappedItem && mappedItem.icon) {
    return mappedItem.icon;
  }
  if (item.type === WebDavFileType.DIRECTORY || item.type === FileType.PPT) {
    if (item.info?.presentation) {
      return getThumbnail(item);
    }
    return icons.directory;
  }

  return icons.unsupported;
};

export const getFileType = (
  file: File
): FileType | WebDavFileType.DIRECTORY => {
  if (file.type === "file" && file.mime) {
    const selected = fileMapper[file.mime];
    if (selected) {
      return fileMapper[file.mime].type;
    }
    return FileType.UNSPECIFIED;
  }
  if (file.info && file.info.presentation) {
    return FileType.PPT;
  }
  return WebDavFileType.DIRECTORY;
};

export const cutOffFileExtension = (filename: string): string => {
  return filename.split(".").slice(0, -1).join(".").toLocaleLowerCase();
};

export const getFileSize = (fileSize: number): string => {
  return prettyBytes(fileSize);
};

export const formatDate = (
  date: string,
  locale: Locale | undefined
): string => {
  return format(new Date(date), spacedFormat, { locale });
};

/**
 * sort methods
 */
const sortString = (asc: boolean, menuItem: string) => {
  return (a: any, b: any) =>
    asc
      ? a[menuItem].localeCompare(b[menuItem], "en", { sensitivity: "base" })
      : b[menuItem].localeCompare(a[menuItem], "en", { sensitivity: "base" });
};
const sortNumber = (asc: boolean, menuItem: string) => (a: any, b: any) =>
  !asc ? b[menuItem] - a[menuItem] : a[menuItem] - b[menuItem];
const sortDate = (asc: boolean, menuItem: string) => (a: any, b: any) => {
  const dateA: any = new Date(a[menuItem]);
  const dateB: any = new Date(b[menuItem]);
  return !asc ? dateB - dateA : dateA - dateB;
};

export const sortSubfolders = (
  folders: File[],
  menuItem: string,
  asc: boolean
): File[] => {
  const updatedFolders = folders.map((folder) => {
    return {
      ...folder,
      type: getFileType(folder),
    };
  });
  const sort = () => {
    // items are numbers
    if (menuItem === listViewMenuItems[2].attribute) {
      return sortNumber(asc, menuItem);
    }
    // items are dates
    if (menuItem === listViewMenuItems[3].attribute) {
      return sortDate(asc, menuItem);
    }
    // items are strings
    return sortString(asc, menuItem);
  };

  return updatedFolders.sort(sort());
};

/**
 * return all selected folders
 * @param allFolders folders to choose from
 * @param selectedIds list of File.etags
 */
export const getSelectedFolders = (
  allFolders: File[],
  selectedIds: string[]
): File[] => {
  return allFolders.filter((item: File) => {
    const found = selectedIds.find((id) => id === item.etag);
    return item.etag === found;
  });
};

/**
 * Returns string that could be used in scr tag (base64 tag)
 * @param file
 */
export const getBase64SrcForFile = async (file: File): Promise<string> => {
  const content = (await communication.webDavClientWrapper.getFileContents(
    file.filename
  )) as ArrayBufferLike;

  const base64 = btoa(
    new Uint8Array(content).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  );
  return `data:${file.mime};base64,${base64}`;
};

/**
 * Download file with content
 * @param path
 */
export const getFile = async (path: string): Promise<File | undefined> => {
  try {
    const file = (await communication.webDavClientWrapper.stat(
      path
    )) as FileStat;

    return { ...file, downloadUrl: await getBase64SrcForFile(file) };
  } catch (e) {
    return undefined;
  }
};

/**
 * Download file with content using download link
 * @param path
 */
export const getFileWithUrl = async (
  path: string,
  loggedUser?: User
): Promise<string | undefined> => {
  const userName = getUsername(loggedUser);
  const wholePath = `/files/${userName}${path}`;
  let url: string | undefined;

  try {
    const file: File = (await communication.webDavClientWrapper.stat(
      wholePath
    )) as FileStat;

    url = await getBase64SrcForFile(file);
  } catch (error) {
    Sentry.captureException(error);
  }

  return url;
};

/**
 * Download file using download link
 * @param resourceName filename
 */
export const getFileAuthUrl = (resourceName: string) => {
  const token = getJSON(TOKEN_COOKIE_NAME);

  let authURI = `&type=Basic&token=${encodeURIComponent("Z3Vlc3Q6Z3Vlc3Q=")}`;

  if (token) {
    authURI = `&type=Bearer&token=${encodeURIComponent(token.access_token)}`;
  }

  return `${
    process.env.REACT_APP_RSAM
  }/ocs/v1.php/apps/rsam/api/v1/file/download?path=${encodeURIComponent(
    encodeBase64(resourceName.replace(/ /g, "%20"))
  )}${authURI}`;
};

/**
 * Download file with content / download URL or Base64
 * @param path
 */
export const getFileWithUrlOrBase = async (
  path: string,
  loggedUser?: User
): Promise<File> => {
  const userName = getUsername(loggedUser);
  const wholePath = `/files/${userName}${path}`;
  const file: File = (await communication.webDavClientWrapper.stat(
    wholePath
  )) as FileStat;

  let downloadUrl;

  if (
    file.mime &&
    (file.mime === MimeType.Pdf || file.mime === MimeType.Video)
  ) {
    downloadUrl = getFileAuthUrl(path);
  }

  file.downloadUrl = downloadUrl || (await getBase64SrcForFile(file));
  return file;
};

export const fetchNoteContent = async (file: File): Promise<any> => {
  const content = (await communication.webDavClientWrapper.getFileContents(
    file.filename
  )) as ArrayBufferLike;

  const dataView = new DataView(content);
  const decoder = new TextDecoder("utf8");
  const response = JSON.parse(decoder.decode(dataView));
  return response;
};

export const verifyShouldDownload = (file: File): boolean => {
  if (file.mime) {
    return !typesToOpen.includes(file.mime);
  }
  return false;
};

export const createDownloadUrl = async (filename: string): Promise<string> => {
  const content = await communication.webDavClientWrapper.getFileContents(
    filename
  );

  const blob = new Blob([content as ArrayBufferLike]);
  const blobUrl = window.URL.createObjectURL(blob);
  return blobUrl;
};

export const adjustFileWithMetaData = async (
  file: File,
  filesInfo: FileInfo[]
): Promise<File> => {
  const adjustedFile = { ...file };

  const info = filesInfo.find((f) => {
    const fileInfoPath = f.path ? checkSlashAtEnd(f.path) : "";
    const filePath = checkSlashAtEnd(getDataPathFromFilename(file.filename));
    return filePath === fileInfoPath;
  });

  if (info) {
    adjustedFile.info = info;
  }
  const icon = await getItemIcon(adjustedFile);

  return { ...adjustedFile, icon };
};

export const getParentPath = (path: string | undefined): string | undefined => {
  if (!path || path === "/") {
    return undefined;
  }

  return `/${path
    .split("/")
    .filter((_, index, arr) => index > 0 && index < arr.length - 1)
    .join("/")}`;
};

export const getBaseName = (isDirectory: boolean, path: string): string => {
  const hasDash = path[path.length - 1] === "/";
  const directoryPath = path.slice(0, path.length - 1);

  return isDirectory && hasDash
    ? directoryPath.slice(directoryPath.lastIndexOf("/") + 1)
    : path.slice(path.lastIndexOf("/") + 1);
};
const extractInfo = (xmlFile: any): File | undefined => {
  const path: string = xmlFile["d:href"] && decodeURI(xmlFile["d:href"]._text);
  const attributes =
    xmlFile["d:propstat"] &&
    xmlFile["d:propstat"][0] &&
    xmlFile["d:propstat"][0]["d:prop"]
      ? xmlFile["d:propstat"][0]["d:prop"]
      : undefined;

  const isDirectory =
    attributes["d:resourcetype"] &&
    !!attributes["d:resourcetype"]["d:collection"];

  // filter out ROOT file
  if (attributes && path) {
    const file: File = {
      basename: getBaseName(isDirectory, path),
      etag: attributes["d:getetag"] && attributes["d:getetag"]._text,
      filename: `/${path.split("/").slice(3).join("/")}`,
      size: attributes["oc:size"] && attributes["oc:size"]._text,
      lastmod:
        attributes["d:getlastmodified"] &&
        attributes["d:getlastmodified"]._text,
      type: isDirectory ? WebDavFileType.DIRECTORY : WebDavFileType.FILE,
      isFavourite:
        attributes["oc:favorite"] &&
        attributes["oc:favorite"]._text &&
        attributes["oc:favorite"]._text === "1",
      mime:
        attributes["d:getcontenttype"] && attributes["d:getcontenttype"]._text,
    };

    return file;
  }

  return undefined;
};

/**
 * Maps JSON to desired object used in application
 * @param jsonData JSON response from WebDav query
 * @param wholePath path to the root - will be filtered out
 */
export const mapJSONToFolderData = (jsonData: any): File[] => {
  const xmlObject = JSON.parse(jsonData);
  const xmlFiles =
    xmlObject["d:multistatus"] && xmlObject["d:multistatus"]["d:response"]
      ? xmlObject["d:multistatus"]["d:response"]
      : undefined;

  if (xmlFiles) {
    // if is single file then move it to array of single element
    const xmlFilesArray = xmlFiles.length > 0 ? xmlFiles : [xmlFiles];

    const folders: File[] = xmlFilesArray
      .map((file: any) => extractInfo(file))
      .filter((file: any) => file !== undefined);

    return folders;
  }

  return [];
};

/**
 * separate parent from child folders
 * @param foldersWithParent
 * @param parentPath
 */
export const separateParent = (
  foldersWithParent: File[],
  parentPath: string
): { folders: File[]; parent: File | undefined } => {
  let parent: File | undefined;
  const foldersWithoutParent = foldersWithParent.filter((item: File) => {
    if (parentPath === item.filename) {
      parent = item;
    }
    return parentPath !== item.filename;
  });

  return {
    folders: foldersWithoutParent,
    parent,
  };
};

/**
 * Returns translated meta filename
 * @param product folder to translate
 * @param activeLang active language to select
 */
export const getMetaName = (product: File, activeLang: string): string => {
  const metaName =
    (product.info?.meta?.translations &&
      product.info.meta.translations[activeLang]?.filename) ||
    product.basename;

  return metaName;
};

/**
 * Returns translated meta description
 * @param product folder to translate
 * @param activeLang active language to select
 */
export const getMetaDescription = (
  product: File,
  activeLang: string
): string => {
  const metaName =
    (product.info?.meta?.translations &&
      product.info.meta.translations[activeLang]?.filename) ||
    "";

  return metaName;
};
