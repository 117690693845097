/** @jsx jsx */

import { FC } from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";
import { Grid, useTheme, useMediaQuery } from "@material-ui/core";
import * as ReactVideo from "video-react";

import { FileProps } from "../../helpers/interfaces/general";
import { MPX } from "../../styles/themes";

export const VideoDetail: FC<FileProps> = ({ file }: FileProps) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const uri = file.downloadUrl;

  const { Player } = ReactVideo;
  return (
    <Grid container justify="center" alignItems="center" css={containerCss}>
      <Player css={videoCss(isSmall)}>
        <source src={uri} />
      </Player>
    </Grid>
  );
};

const paddingTop = MPX * 5;
const containerCss: SerializedStyles = css`
  padding-top: ${paddingTop}px !important;
  width: 100% !important;
  height: 500px !important;
`;

// video-react-big-play-button dimensions are width 86 and height 41
const buttonHeightHalf = 20.5;
const buttonWidthHalf = 43;
export const videoCss = (
  isSmall: boolean,
  isInPpt?: boolean
): SerializedStyles => css`
  padding: 0 !important;
  height: 100% !important;
  ${!isInPpt && `width: ${isSmall ? MPX * 30 : MPX * 20}% !important;`}
  overflow: hidden;
  .video-react-big-play-button {
    top: calc(50% - ${buttonHeightHalf}px) !important;
    left: calc(50% - ${buttonWidthHalf}px) !important;
  }
`;
