/** @jsx jsx */
import { FC, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { css, jsx } from "@emotion/core";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Trans } from "@lingui/macro";
import { setSnack } from "../../store/general/actions";
import { Colors, MPX } from "../../styles/themes";
import { SnackbarInterface } from "../../helpers/interfaces/Notification";

interface AppSnackbarProps {
  snack: SnackbarInterface | undefined;
}

export const AppSnackbar: FC<AppSnackbarProps> = ({ snack }) => {
  const [openSnack, toggleSnack] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (snack && !openSnack) {
      toggleSnack(true);
    }
  }, [snack, openSnack]);

  const onSnackClose = (): void => {
    dispatch(
      setSnack({
        snack: undefined,
      })
    );
    toggleSnack(false);
  };

  return snack ? (
    <Snackbar
      css={alertCss}
      open={openSnack}
      autoHideDuration={1000000}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClose={onSnackClose}
    >
      <Alert
        onClose={onSnackClose}
        severity={snack.severity ? snack.severity : "error"}
      >
        <div css={clipboardTextCss}>
          <Trans>{snack.message}</Trans>
        </div>
      </Alert>
    </Snackbar>
  ) : (
    <div />
  );
};

export const clipboardTextCss = css`
  white-space: pre-line;
`;

export const alertCss = css`
  .MuiAlert-standardSuccess {
    background-color: ${Colors.primary} !important;
    font-size: ${MPX * 3.5}px;
    color: white !important;
    display: flex;
    align-items: center;
  }
  .MuiAlert-standardError {
    font-size: ${MPX * 3.5}px;
    display: flex;
    align-items: center;
  }
`;
