import { createSelector } from "reselect";
import { AppLanguages } from "../../helpers/constants/languages";
import { removeDuplicateNotifications } from "../../helpers/functions/commons";
import { State } from "../combinedReducers";
import { File } from "../../helpers/interfaces/file";
import { Notification } from "../../helpers/interfaces/Notification";

export const getSelectedLanguage = (state: State): AppLanguages =>
  state.general.language;

const filesReducer = (state: State): File[] => state.general.folders;
const notifications = (state: State): Notification[] =>
  state.general.notifications;

export const filterNonVisibleFiles = createSelector(filesReducer, (files) => {
  return files.filter((c) => c.info && c.info.isVisible);
});

export const filterNonFavourites = (files: File[]): File[] => {
  return files.filter((f) => f.isFavourite === true);
};

export const notificationSelector = createSelector(notifications, (nots) => {
  const sortedNots = [...nots].sort(
    (a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime()
  );

  const removedDuplicates = removeDuplicateNotifications(sortedNots);
  return removedDuplicates;
});

export const notificationUnreadCountSelector = createSelector(
  notifications,
  (nots) => {
    if (nots && nots.length) {
      return nots.filter((n) => !n.isRead).length;
    }
    return 0;
  }
);
export const filterMounted = (files: File[]): File[] => {
  return files.filter((f) => f.info && f.info.isMounted === true);
};

export const filterMyFiles = createSelector(filesReducer, (files) => {
  const visibleFiles = files.filter((c) => c.info && c.info.isVisible);
  const publicFolders = files
    .filter((f) => f.info && f.info.isMounted)
    .map((f) => f.filename);
  const myFiles = visibleFiles.filter(
    (f) =>
      f.info &&
      !!f.info.isMounted === false &&
      !!publicFolders.find((pf) => pf.includes(f.filename)) === false
  );

  return myFiles;
});
