import { i18nMark } from "@lingui/core";
import { format } from "date-fns";
import { Note } from "../interfaces/note";
import { longDateFormat } from "../constants/common";

const getNoteDate = (): string => {
  return format(new Date(), longDateFormat);
};

export const initNote: Note = {
  title: `${i18nMark("Note")}-${getNoteDate()}`,
  base64string: "",
  fullName: "",
  email: "",
  phone: "",
  company: "",
  date: "",
  note: "",
};
