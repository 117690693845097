export enum XMLRequestEnum {
  FoldersFetch = "FoldersFetch",
  FavouritesFetch = "FavouritesFetch",
  FavouritesPost = "FavouritesPost",
}

export interface RequestAndParams {
  requestType: XMLRequestEnum;
  body: (isFav?: 0 | 1) => string;
  method: "PROPFIND" | "PROPPATCH" | "REPORT";
  depth?: number;
}

const fileProps = `<d:prop>
<d:resourcetype/>
<d:getetag/>
<oc:fileid/>
<oc:permissions/>
<oc:favorite/>
<oc:size/>
<d:getlastmodified/>
<d:getcontenttype/>
<d:displayname/>
<oc:share-types/>
</d:prop>`;

const foldersFetchBody = (): string => `<?xml version="1.0" encoding="UTF-8" ?>
<d:propfind xmlns:d="DAV:" xmlns:oc="http://owncloud.org/ns" xmlns:nc="http://nextcloud.org/ns">
${fileProps}
</d:propfind>`;

const favouritesFetchBody = (): string => `<?xml version="1.0" encoding="UTF-8" ?>
<oc:filter-files  xmlns:d="DAV:" xmlns:oc="http://owncloud.org/ns" xmlns:nc="http://nextcloud.org/ns">
  <oc:filter-rules>
    <oc:favorite>1</oc:favorite>
  </oc:filter-rules>
${fileProps}
</oc:filter-files>`;

const favouritePostBody = (
  isFav: 0 | 1
): string => `<?xml version="1.0" encoding="UTF-8" ?>
<d:propertyupdate xmlns:d="DAV:" xmlns:oc="http://owncloud.org/ns">
  <d:set>
        <d:prop>
          <oc:favorite>${isFav}</oc:favorite>
        </d:prop>
  </d:set>
</d:propertyupdate>`;

const requestAndParams: RequestAndParams[] = [
  {
    requestType: XMLRequestEnum.FoldersFetch,
    body: foldersFetchBody,
    method: "PROPFIND",
    depth: 1,
  },
  {
    requestType: XMLRequestEnum.FavouritesFetch,
    body: favouritesFetchBody,
    method: "REPORT",
  },
  {
    requestType: XMLRequestEnum.FavouritesPost,
    body: (isFav): string => favouritePostBody(isFav || 0),
    method: "PROPPATCH",
  },
];

export const getCustomRequestParams = (
  type: XMLRequestEnum,
  depth = 1,
  isFav?: 0 | 1
): any => {
  const request = requestAndParams.find((r) => r.requestType === type);
  if (request) {
    return {
      method: request.method,
      headers: {
        "Content-Type": "text/plain",
        Accept: "*/*",
        // 1 for WEB, 3 or more for mobile
        Depth: depth,
      },
      responseType: "text",
      data: request.body(isFav),
    };
  }
  return undefined;
};
