/** @jsx jsx */
import React, { FC } from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";
import { Grid, Hidden, Drawer } from "@material-ui/core";
import { LeftMenu } from "./LeftMenu";
import { TOP_BAR_HEIGHT } from "../../styles/dimensions";
import { MPX } from "../../styles/themes";

interface DrawerMenuProps {
  isVisible: boolean;
  closeDrawer: () => void;
}

export const DrawerMenu: FC<DrawerMenuProps> = ({
  isVisible,
  closeDrawer,
}: DrawerMenuProps) => {
  return (
    <React.Fragment>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor="left"
          open={isVisible}
          onClose={closeDrawer}
          css={leftMenuDrawerCss}
        >
          <LeftMenu closeDrawer={closeDrawer} />
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Grid item md={3} lg={2} xl={1} css={drawerWrapCss}>
          <LeftMenu closeDrawer={closeDrawer} />
        </Grid>
      </Hidden>
    </React.Fragment>
  );
};
const drawerWrapCss: SerializedStyles = css`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  &::-webkit-scrollbar {
    width: ${MPX * 2}px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: ${MPX * 2.5}px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: ${MPX * 2.5}px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
`;

const leftMenuDrawerCss: SerializedStyles = css`
  height: 100% !important;
  top: ${TOP_BAR_HEIGHT}px !important;
  .MuiBackdrop-root {
    top: ${TOP_BAR_HEIGHT}px !important;
  }
  .MuiDrawer-paper {
    top: ${TOP_BAR_HEIGHT}px !important;
  }
`;
