import { QueryParams } from "../interfaces/queryParam";
import { DirectoryType } from "../enums/dirType";

export const getTitleFromQueryParams = (queryParams: QueryParams[]): string => {
  if (queryParams.length > 0) {
    return queryParams[queryParams.length - 1].value;
  }
  return "";
};

export const checkSlashAtEnd = (path: string): string => {
  let lastChar = "";
  if (path.length > 0) {
    lastChar = path[path.length - 1];
  }
  const getSlash = lastChar === "/" ? "" : "/";
  return `${path}${getSlash}`;
};
export const getPathName = (
  currentRoute: string,
  nextParam: string
): string => {
  return `${checkSlashAtEnd(currentRoute)}${nextParam}`;
};

const queryParamsToDataPath = (queryParams: QueryParams[]): string => {
  const valueArray = queryParams.map((param) => {
    return param.value;
  });
  return valueArray.length > 0 ? valueArray.join("/") : "";
};

export const getDataPath = (
  category: string | undefined,
  queryParams: QueryParams[]
): string => {
  if (!category) {
    return "";
  }

  const valueArray = queryParams.map((param) => param.value);
  const queryElements = [category, ...valueArray];
  const checkIfNotAllSameParams = queryElements.every(
    (v) => v === queryElements[0]
  );

  return checkIfNotAllSameParams
    ? `/${category}`
    : `/${category}/${queryParamsToDataPath(queryParams)}`;
};

export const createNestedQuery = (
  queryParams: QueryParams[],
  basename: string,
  type: DirectoryType = DirectoryType.SUBFOLDER
): string => {
  const keyValueArray = queryParams.map((param) => {
    return `${param.key}=${param.value}`;
  });
  const x = `${keyValueArray.join("&")}&${type}${
    type === DirectoryType.SUBFOLDER ? keyValueArray.length : ""
  }=${basename}`;

  return x;
};

export const getDataPathFromFilename = (fullPath: string): string => {
  return `/${fullPath.split("/").slice(3).join("/")}`;
};

/**
 * @param searchParams location.search
 * return QueryParams Array
 */
export const getQueryParams = (searchParams: string): QueryParams[] => {
  const params = new URLSearchParams(searchParams);
  const queryParams: QueryParams[] = [];
  // the params instance doesn't include a map method, it isn't an Array
  params.forEach((value, key) => {
    queryParams.push({ key, value });
  });
  return queryParams;
};
