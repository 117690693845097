export enum RoutePaths {
  PRODUCTS = "products",
  CATEGORY = "category",
  FOLDER = "folder",
  FILEDETAIL = "filedetail",
  FILES = "files",
  PPTFILE = "profile",
  LOGIN = "login",
  FAVORITES = "favorites",
  CONTACTFORM = "contact-form",
  MYFILES = "my-files",
  APPS = "apps",
  SPINNDATA = "spinn-data",
  TOOLS = "tools",
  SETTINGS = "settings",
  NEWNOTE = "new-note",
  NOTIFICATIONS = "notifications",
}
