/** @jsx jsx */

import { FC, useState } from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";
import * as PDF from "react-pdf-highlighter";
import { Grid, Dialog, CircularProgress } from "@material-ui/core";
import { FileProps } from "../../helpers/interfaces/general";
import { Colors } from "../../styles/themes";

export const PdfDetail: FC<FileProps> = ({ file }: FileProps) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const renderPdf = (): JSX.Element => (
    <div css={dialogPdfWrapCss}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        css={dialogPdfCss}
      >
        <div css={wrapCss}>
          <iframe
            src={file.downloadUrl!}
            width="100%"
            height="100%"
            style={{ border: "0px", borderWidth: "0px" }}
            title={file.basename}
          />
        </div>
      </Grid>
    </div>
  );

  return (
    <div css={containerCss}>
      <Grid container direction="column" alignItems="center">
        {renderPdf()}
      </Grid>
      <Dialog
        fullScreen
        open={fullScreen}
        onClose={(): void => setFullScreen(false)}
      >
        {renderPdf()}
      </Dialog>
    </div>
  );
};

const containerCss: SerializedStyles = css`
  background-color: ${Colors.lightGrey};
  height: 100% !important;
`;

const dialogPdfWrapCss: SerializedStyles = css`
  width: 100% !important;
  height: 100% !important;
  background-color: ${Colors.lightGrey};
`;
const dialogPdfCss: SerializedStyles = css`
  background-color: ${Colors.lightGrey};
  flex-wrap: nowrap !important;
`;
const wrapCss = (): SerializedStyles => css`
  position: relative;
  width: 100%;
  height: calc(100vh - 150px); /*  Height of menu */
`;
