import { reducer } from "ts-action";
import { on } from "ts-action-immer";
import { getJSON } from "js-cookie";
import { AppLanguages, LANGUAGE } from "../../helpers/constants/languages";
import { LoadingState } from "../../helpers/enums/general";
import { File, User } from "../../helpers/interfaces/file";

import {
  setLanguage,
  setRequestState,
  setFolders,
  setNotification,
  setFolderFetchState,
  setUser,
  setParent,
  setFavouritesRequestState,
  setFavourite,
  setFileUploadState,
  setNote,
  markNotificationAsRead,
  addToReadNotifications,
  setShareLink,
  setShareLinkState,
  setNotificationAllowedState,
  setSnack,
  setDownloadFileState,
  setParentFavourite,
  setCurrentSlide,
  setNotificationState,
} from "./actions";
import {
  Notification,
  SnackbarInterface,
} from "../../helpers/interfaces/Notification";
import { USER_COOKIE_NAME } from "../../helpers/constants/cookie";
import { Note } from "../../helpers/interfaces/note";
import { localStorageNotificationsAllowedHelper } from "../../helpers/functions/localstorageHelpers";
import { PptSlideShareRefs } from "../../helpers/interfaces/pptSlides";

export interface State {
  language: AppLanguages;
  requestState: LoadingState;
  folderLoadState: LoadingState;
  folders: File[];
  parent: File | undefined;
  loggedUser: User | undefined;
  favouritesLoadState: LoadingState;
  fileUploadState: LoadingState;
  notifications: Notification[];
  note: Note | undefined;
  readNotifications: Notification[];
  shareLinkState: LoadingState;
  shareLinks: string[];
  notificationsAllowed: boolean | undefined;
  snack: SnackbarInterface | undefined;
  downloadState: LoadingState;
  currentSlide: PptSlideShareRefs | undefined;
  notificationLoadState: LoadingState;
}

export const initialState: State = {
  language: LANGUAGE,
  requestState: LoadingState.Initial,
  folderLoadState: LoadingState.Initial,
  folders: [],
  loggedUser: getJSON(USER_COOKIE_NAME),
  parent: undefined,
  favouritesLoadState: LoadingState.Initial,
  fileUploadState: LoadingState.Initial,
  notifications: [],
  note: undefined,
  readNotifications: [],
  shareLinkState: LoadingState.Initial,
  shareLinks: [],
  notificationsAllowed: localStorageNotificationsAllowedHelper(),
  snack: undefined,
  downloadState: LoadingState.Initial,
  currentSlide: undefined,
  notificationLoadState: LoadingState.Initial,
};

export const generalReducer = reducer<State>(
  initialState,
  on(setLanguage, (state, { payload }) => {
    const { language } = payload;
    state.language = language;
  }),
  on(setSnack, (state, { payload }) => {
    const { snack } = payload;
    state.snack = snack;
  }),
  on(setRequestState, (state, { payload }) => {
    const { loadState } = payload;
    state.requestState = loadState;
  }),
  on(setNotificationState, (state, { payload }) => {
    const { notificationLoadState } = payload;
    state.notificationLoadState = notificationLoadState;
  }),
  on(setDownloadFileState, (state, { payload }) => {
    const { loadState } = payload;
    state.downloadState = loadState;
  }),
  on(setFavouritesRequestState, (state, { payload }) => {
    const { favouritesLoadState } = payload;
    state.favouritesLoadState = favouritesLoadState;
  }),
  on(setFileUploadState, (state, { payload }) => {
    const { fileUploadState } = payload;
    state.fileUploadState = fileUploadState;
  }),
  on(setNote, (state, { payload }) => {
    const { note } = payload;
    state.note = note;
  }),
  on(setFavourite, (state, { payload }) => {
    const { favourite } = payload;
    state.folders = state.folders.map((f: File) =>
      f.filename === favourite ? { ...f, isFavourite: !f.isFavourite } : f
    );
  }),
  on(setParentFavourite, (state) => {
    if (state.parent) {
      state.parent = {
        ...state.parent,
        isFavourite: !state.parent.isFavourite,
      };
    }
  }),
  on(setUser, (state, { payload }) => {
    const { user } = payload;
    state.loggedUser = user;
  }),
  on(setFolderFetchState, (state, { payload }) => {
    const { folderLoadState } = payload;
    state.folderLoadState = folderLoadState;
  }),
  on(setFolders, (state, { payload }) => {
    const { folders } = payload;
    state.folders = folders;
  }),
  on(setParent, (state, { payload }) => {
    const { parent } = payload;
    state.parent = parent;
  }),
  on(setNotification, (state, { payload }) => {
    const { notifications } = payload;
    state.notifications = notifications;
  }),
  on(setNotificationAllowedState, (state, { payload }) => {
    state.notificationsAllowed = payload.state;
  }),
  on(addToReadNotifications, (state, { payload }) => {
    const { notification } = payload;
    state.readNotifications.push(notification);
  }),
  on(setShareLink, (state, { payload }) => {
    const { shareLinks } = payload;
    state.shareLinks = shareLinks;
  }),

  on(setShareLinkState, (state, { payload }) => {
    const { shareLinkState } = payload;
    state.shareLinkState = shareLinkState;
  }),
  on(markNotificationAsRead, (state, { payload }) => {
    const { id } = payload;
    state.notifications = state.notifications.map((not) =>
      not.notification_id === id ? { ...not, isRead: true } : not
    );
  }),
  on(setCurrentSlide, (state, { payload }) => {
    const { currentSlide } = payload;
    state.currentSlide = currentSlide;
  })
);
