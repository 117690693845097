import { i18nMark } from "@lingui/core";

export interface LVMenuItemInterface {
  text: string;
  attribute: string;
}
export const listViewMenuItems: LVMenuItemInterface[] = [
  {
    text: i18nMark("name"),
    attribute: "basename",
  },
  {
    text: i18nMark("type"),
    attribute: "type",
  },
  {
    text: i18nMark("size"),
    attribute: "size",
  },
  {
    text: i18nMark("created"),
    attribute: "lastmod",
  },
];
