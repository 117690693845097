/** @jsx jsx */

import { FC, useState } from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";
import { Grid, Dialog, useTheme, useMediaQuery } from "@material-ui/core";

import { FileProps } from "../../helpers/interfaces/general";
import { Colors, MPX } from "../../styles/themes";

export const ImageDetail: FC<FileProps> = ({ file }: FileProps) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const handleFullScreen = (): void => {
    setFullScreen(!fullScreen);
  };

  const renderImage = (): JSX.Element => (
    <div
      onClick={handleFullScreen}
      css={fullScreen ? fullCss : imageWrapCss(isSmall)}
    >
      <img
        src={file.downloadUrl}
        css={fullScreen ? dialogImgCss : imageCss}
        alt={file.basename}
      />
    </div>
  );
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      css={containerCss(fullScreen)}
    >
      {renderImage()}
      <Dialog
        fullScreen
        open={fullScreen}
        onClose={(): void => setFullScreen(false)}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          css={containerCss(fullScreen)}
        >
          {renderImage()}
        </Grid>
      </Dialog>
    </Grid>
  );
};

const containerCss = (fullScreen: boolean): SerializedStyles => css`
  height: 100% !important;
  padding: ${MPX * 1.25}%;
  background-color: ${fullScreen ? Colors.black : Colors.white};
`;
const imageWrapCss = (isSmall: boolean): SerializedStyles => css`
  width: ${isSmall ? MPX * 20 : MPX * 15}%;
`;
const imageCss: SerializedStyles = css`
  height: 100%;
  width: 100%;
  object-fit: contain;
  cursor: pointer;
`;

const fullCss: SerializedStyles = css`
  width: 100%;
  height: 100%;
`;
const dialogImgCss: SerializedStyles = css`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
