import { RouteProps } from "react-router-dom";
import { lazy } from "react";
import { i18nMark } from "@lingui/core";
import { RoutePaths } from "../helpers/enums/RoutePaths";

export interface Route {
  path: string;
  props: RouteProps;
  authRoute?: boolean;
}

const Products = lazy(() => import("../pages/products/ProductsContainer"));
const Favorites = lazy(() => import("../pages/favourites/FavouritesContainer"));
const MyFiles = lazy(() => import("../pages/myFiles/MyfilesContainer"));
const OtherApps = lazy(() => import("../pages/OtherApps"));
const SpinnDataApp = lazy(() => import("../pages/apps/SpinnDataAppContainer"));
const Tools = lazy(() => import("../pages/tools/ToolsContainer"));
const Notifications = lazy(() =>
  import("../pages/notifications/NotificationListContainer")
);

const Categories = lazy(() => import("../pages/products/CategoriesContainer"));
const Subcategories = lazy(() =>
  import("../pages/products/SubcategoriesContainer")
);

const Settings = lazy(() => import("../pages/tools/SettingsContainer"));
const NewNote = lazy(() => import("../pages/tools/NewNoteContainer"));
const SSOLogin = lazy(() => import("../pages/login/SSOLoginContainer"));

/* eslint-disable global-require */
export interface MenuItem extends Route {
  name: string;
  icon: string;
  iconActive: string;
}
export const menuItems: MenuItem[] = [
  {
    name: i18nMark("Products"),
    icon: require("../assets/bottom-icons/knot.png"),
    iconActive: require("../assets/bottom-icons/knot-active.png"),
    path: `/${RoutePaths.PRODUCTS}`,
    props: {
      component: Products,
      exact: true,
    },
  },
  {
    name: i18nMark("Favorites"),
    icon: require("../assets/bottom-icons/star.png"),
    iconActive: require("../assets/bottom-icons/star-active.png"),
    path: `/${RoutePaths.FAVORITES}`,
    props: {
      component: Favorites,
      exact: true,
    },
  },
  {
    name: i18nMark("My Files"),
    icon: require("../assets/bottom-icons/folder.png"),
    iconActive: require("../assets/bottom-icons/folder-active.png"),
    path: `/${RoutePaths.MYFILES}`,
    props: {
      component: MyFiles,
      exact: true,
    },
  },
  {
    name: i18nMark("Other apps"),
    icon: require("../assets/bottom-icons/ruler.png"),
    iconActive: require("../assets/bottom-icons/ruler-active.png"),
    path: `/${RoutePaths.APPS}`,
    props: {
      component: OtherApps,
      exact: true,
    },
  },
  {
    name: i18nMark("Tools"),
    icon: require("../assets/bottom-icons/settings.png"),
    iconActive: require("../assets/bottom-icons/settings-active.png"),
    path: `/${RoutePaths.TOOLS}`,
    props: {
      component: Tools,
      exact: true,
    },
  },
];
/* eslint-enable global-require */
export const productPages: Route[] = [
  {
    path: `/${RoutePaths.PRODUCTS}/:${RoutePaths.CATEGORY}`,
    props: {
      component: Categories,
      exact: true,
    },
  },
  {
    path: `/${RoutePaths.PRODUCTS}/:${RoutePaths.CATEGORY}/:${RoutePaths.FOLDER}`,
    props: {
      component: Subcategories,
      exact: false,
    },
  },
];

export const toolPages: Route[] = [
  {
    path: `/${RoutePaths.TOOLS}/${RoutePaths.SETTINGS}`,
    props: {
      component: Settings,
      exact: true,
    },
  },
  {
    path: `/${RoutePaths.TOOLS}/${RoutePaths.NEWNOTE}`,
    props: {
      component: NewNote,
      exact: true,
    },
    authRoute: true,
  },
  {
    path: `/${RoutePaths.NOTIFICATIONS}`,
    props: {
      component: Notifications,
    },
    authRoute: true,
  },
  {
    path: `/${RoutePaths.LOGIN}/oauthCb`,
    props: {
      component: SSOLogin,
      exact: true,
    },
  },
];

export const appsPages: Route[] = [
  {
    path: `/${RoutePaths.APPS}/${RoutePaths.SPINNDATA}`,
    props: {
      component: SpinnDataApp,
      exact: true,
    },
  },
];

export const routes: (Route | MenuItem)[] = [
  ...menuItems,
  ...productPages,
  ...toolPages,
  ...appsPages,
];
