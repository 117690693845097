import { remove, get } from "js-cookie";

export const TOKEN_COOKIE_NAME = "artin-rieter-saml-token";
export const USER_COOKIE_NAME = "artin-rieter-saml-user";
export const LOCALSTORAGE_NOTIFICATIONS_ALLOWED =
  "artin-rieter-notifications-allowed";
export const TOKEN_COOKIE_EXPIRATION_IN_DAYS = 1 / 24;

export const removeAllCookies = (): void => {
  const allCookies = get();
  Object.keys(allCookies).forEach((key) => {
    console.log(`Removing cookies ${key}`);
    remove(key);
  });
};
