/** @jsx jsx */

import { css, jsx, SerializedStyles } from "@emotion/core";
import { Grid, Button } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { File } from "../interfaces/file";
import { redirectUrlForHref } from "../constants/common";
import { MPX, Colors } from "../../styles/themes";

export const wrapAsLink = (
  content: JSX.Element,

  file: File,

  shouldDownload: boolean
): JSX.Element => {
  if (shouldDownload) {
    return (
      <a href={file.downloadUrl} download={file.basename} css={linkCss}>
        {content}
      </a>
    );
  }

  return content;
};

const linkCss: SerializedStyles = css`
  text-decoration: none;
`;

export const renderNoUser = (): JSX.Element => (
  <Grid container justify="center" alignItems="center" css={containerCss}>
    <Grid item xs={12} css={noUserTextCss}>
      <Trans>You need to Log in to browse this page</Trans>
    </Grid>
    <Button css={noUserButtonCss} href={redirectUrlForHref}>
      <span css={buttonTextCss}>
        <Trans>Log In</Trans>
      </span>

      <ArrowRightAltIcon css={rightArrowCss} />
    </Button>
  </Grid>
);

const containerCss: SerializedStyles = css`
  margin-top: 15%;
`;

const noUserTextCss: SerializedStyles = css`
  text-align: center;
  margin-bottom: ${MPX * 15}px !important;
  font-weight: bold;
  font-size: ${MPX * 7}px;
  color: ${Colors.darkGrey};
`;

const noUserButtonCss: SerializedStyles = css`
  justify-content: "space-between";
  min-width: ${MPX * 47.5}px !important;
  min-height: ${MPX * 17.5}px !important;
  background-color: ${Colors.black} !important;
  border-radius: 0px !important;
`;

const buttonTextCss: SerializedStyles = css`
  font-size: ${MPX * 4.5}px;
  text-transform: uppercase;
  color: white;
`;

const rightArrowCss: SerializedStyles = css`
  color: ${Colors.secondary} !important;
  display: flex;
  align-items: inherit;
  justify-content: space-around;
`;
