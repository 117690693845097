/** @jsx jsx */
import React from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";
import { Grid, CircularProgress } from "@material-ui/core";

interface LoadingInterface {
  customCss?: SerializedStyles;
}
export const Loading: React.FC<LoadingInterface> = ({
  customCss,
}: LoadingInterface): JSX.Element => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      css={!customCss ? wrapCss : customCss}
    >
      <CircularProgress />
    </Grid>
  );
};

const wrapCss: SerializedStyles = css`
  height: 100% !important;
`;
