/** @jsx jsx */
import { FC } from "react";
import { jsx } from "@emotion/core";
import { useDispatch } from "react-redux";
import { Snackbar, Tooltip, IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { Trans } from "@lingui/macro";
import { LoadingState } from "../../helpers/enums/general";
import { setShareLinkState } from "../../store/general/actions";
import { copyCodeToClipboard } from "../../helpers/functions/commons";
import { clipboardTextCss, alertCss } from "./AppSnackbar";

interface ShareLinkSnackbarProps {
  openSnack: boolean;
  shareLinks: string[];
}

export const ShareLinkSnackbar: FC<ShareLinkSnackbarProps> = ({
  shareLinks,
  openSnack,
}) => {
  const dispatch = useDispatch();
  const linksToClibboard = shareLinks.length
    ? shareLinks.join("\n")
    : shareLinks[0];

  return (
    <Snackbar
      css={alertCss}
      open={openSnack}
      autoHideDuration={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClick={(): void => copyCodeToClipboard(linksToClibboard)}
      onClose={
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        () =>
          dispatch(
            setShareLinkState({
              shareLinkState: LoadingState.Initial,
            })
          )
        // eslint-disable-next-line react/jsx-curly-newline
      }
    >
      <Alert
        icon={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Tooltip title={<Trans>Copy URL</Trans>}>
            <IconButton
              onClick={(): void => copyCodeToClipboard(linksToClibboard)}
            >
              <FileCopyOutlinedIcon fontSize="inherit" color="secondary" />
            </IconButton>
          </Tooltip>
        }
        onClose={
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          () =>
            dispatch(
              setShareLinkState({
                shareLinkState: LoadingState.Initial,
              })
            )
          // eslint-disable-next-line react/jsx-curly-newline
        }
      >
        <div css={clipboardTextCss}>{linksToClibboard}</div>
      </Alert>
    </Snackbar>
  );
};
