import { NotificationDTO, Notification } from "../interfaces/Notification";

export const parseNotificationLink = (
  notification: NotificationDTO
): Notification => {
  const link = notification.link && JSON.parse(notification.link);

  const mappedNotification: Notification = {
    ...notification,
    link: {
      path: link.path,
      mime: link.path_mime,
      type: link.path_type,
      messages: link.messages,
      sync: link.sync,
    },
  };

  return mappedNotification;
};
