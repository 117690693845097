import React, { useEffect, Suspense } from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@material-ui/core";
import { I18nProvider } from "@lingui/react";
import { BrowserRouter, useHistory } from "react-router-dom";
import { Loading } from "./components/shared/Loading";
import { APP_THEME } from "./styles/themes";
import { getSelectedLanguage } from "./store/general/selectors";
import { AppLanguages } from "./helpers/constants/languages";
import catalogZh from "./plugins/locales/zh/messages";
import catalogEn from "./plugins/locales/en/messages";
import AppContainer from "./AppContainer";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const catalogs: { [key in AppLanguages]: any } = {
  zh: catalogZh,
  en: catalogEn,
};

// Serves for route handling
const History = (): JSX.Element => {
  const history = useHistory();

  useEffect(() => {
    // remove on component mount
    localStorage.removeItem("visitedPaths");
    // set router history listener
    return history.listen((location) => {
      if (localStorage) {
        // set application routes to the localStorage object, to use them for backButton
        const previouslyVisitedPaths = localStorage.getItem("visitedPaths");

        if (!previouslyVisitedPaths) {
          localStorage.setItem(
            "visitedPaths",
            `${location.pathname}${location.search}`
          );
        } else {
          localStorage.setItem(
            "visitedPaths",
            `${previouslyVisitedPaths},${location.pathname}${location.search}`
          );
        }
      }
    });
  }, [history]);

  return <AppContainer />;
};

export const Root = (): JSX.Element => {
  const language = useSelector(getSelectedLanguage);

  return (
    <Suspense fallback={<Loading />}>
      <ThemeProvider theme={APP_THEME}>
        <I18nProvider language={language} catalogs={catalogs}>
          <BrowserRouter>
            <History />
          </BrowserRouter>
        </I18nProvider>
      </ThemeProvider>
    </Suspense>
  );
};
