import { getJSON } from "js-cookie";
import {
  LOCALSTORAGE_NOTIFICATIONS_ALLOWED,
  TOKEN_COOKIE_NAME,
} from "../constants/cookie";

export const localStorageNotificationsAllowedHelper = (): boolean =>
  localStorage.getItem(LOCALSTORAGE_NOTIFICATIONS_ALLOWED)
    ? localStorage.getItem(LOCALSTORAGE_NOTIFICATIONS_ALLOWED) === "true"
    : true;

export const getRefreshToken = (): string | undefined => {
  const cookie = getJSON(TOKEN_COOKIE_NAME);
  const refreshToken = cookie ? cookie.refresh_token : undefined;

  return refreshToken;
};
