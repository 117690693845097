/** @jsx jsx */

import { FC } from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";
import { Colors, MPX } from "../../styles/themes";
import { TITLE_HEIGHT, TITLE_PADDING_BOTTOM } from "../../styles/dimensions";

interface PageTitleProps {
  title: JSX.Element;
  color?: string;
  centerTitle?: boolean;
}

export const PageTitle: FC<PageTitleProps> = ({
  title,
  color = Colors.black,
  centerTitle = false,
}: PageTitleProps) => {
  return (
    <div css={containerCss(centerTitle)}>
      <span css={titleCss(color)}>{title}</span>
    </div>
  );
};

const containerCss = (centerTitle: boolean): SerializedStyles => css`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: ${centerTitle ? "center" : "flex-start"};
  height: ${TITLE_HEIGHT}px;
  background-color: ${Colors.white};
  padding-left: ${6 * MPX}px !important;
  padding-bottom: ${TITLE_PADDING_BOTTOM}px;
`;
export const titleCss = (color: string): SerializedStyles => css`
  line-height: ${MPX * 12}px;
  font-size: ${MPX * 12}px;
  font-weight: 600;
  color: ${color};
  text-transform: capitalize;
`;
