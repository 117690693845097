export const noteExt = "note";

export enum MimeType {
  Document = "application/msword",
  DocumentX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  Sheet = "application/vnd.ms-excel",
  SheetX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  Presentation = "application/vnd.ms-powerpoint",
  PresentationX = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  Pdf = "application/pdf",
  Video = "video/mp4",
  ImagePng = "image/png",
  ImageJpg = "image/jpg",
  ImageJpeg = "image/jpeg",
  Markdown = "text/markdown",
  Text = "text/plain",
  Note = "application/octet-stream",
}

export const typesToOpen: string[] = [
  MimeType.Pdf,
  MimeType.Video,
  MimeType.ImagePng,
  MimeType.ImageJpg,
  MimeType.ImageJpeg,
];
