import axios, { AxiosInstance, AxiosResponse } from "axios";
import { createClient, WebDAVClient, Response, AuthType } from "webdav/web";
import { getJSON } from "js-cookie";
import { getCustomRequestParams, XMLRequestEnum } from "./xmlRequests";
import { TOKEN_COOKIE_NAME } from "../constants/cookie";
import store from "../../store";
import { fetchRefreshToken } from "../../store/general/actions";
import { getRefreshToken } from "./localstorageHelpers";

// ONLY FOR TESTING EXPIRING TOKEN, PLEASE KEEP IT HERE
// @ts-ignore
// window.destroyLogin = () => {
//   // @ts-ignore
//   getAccessTokenFromRefresh(getRefreshToken());
// };

export const nextCloudDavURL = process.env.REACT_APP_NEXT_CLOUD_URL ?? "";
export const axiosWebApiUrl = process.env.REACT_APP_WEB_API_URL;
const clientId = process.env.REACT_APP_CLIENT_ID;
const redirectUri = process.env.REACT_APP_REDIRECTION_URL;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
const oauthApi = process.env.REACT_APP_OAUTH_API;

export const guestName = "guest";
const guestPassword = "guest";

export const webDavFactory = (
  token?: any,
  userName: string = guestName,
  password: string = guestPassword
): WebDAVClient => {
  if (token) {
    return createClient(nextCloudDavURL, {
      authType: AuthType.Token,
      token,
    });
  }
  return createClient(nextCloudDavURL, {
    username: userName,
    password,
  });
};

const tryToRefreshAccessToken = () => {
  const refreshToken = getRefreshToken();

  if (refreshToken) {
    store.dispatch(
      // @ts-ignore
      fetchRefreshToken(refreshToken, () => {
        window.location.reload();
      })
    );
  }
};

export const axiosFactory = (token?: string): AxiosInstance => {
  let axiosInstace;

  if (token) {
    axiosInstace = axios.create({
      baseURL: axiosWebApiUrl,
      headers: {
        "OCS-APIRequest": true,
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    axiosInstace = axios.create({
      baseURL: axiosWebApiUrl,
      headers: {
        "OCS-APIRequest": true,
      },
      auth: {
        username: guestName,
        password: guestPassword,
      },
    });
  }

  axiosInstace.interceptors.response.use(
    function (response): AxiosResponse<any> {
      return response;
    },
    function (error): Promise<never> | undefined {
      if (error?.response?.status === 401) {
        tryToRefreshAccessToken();
      } else {
        return Promise.reject(error);
      }

      return undefined;
    }
  );

  return axiosInstace;
};
const cookie = getJSON(TOKEN_COOKIE_NAME);

// eslint-disable-next-line import/no-mutable-exports
export const communication = {
  webDavClientWrapper: webDavFactory(cookie),
  webDavGuestClientWrapper: webDavFactory(),
  axios: axiosFactory(cookie && cookie.access_token),
};

const authAxios = axios.create({
  baseURL: oauthApi,
  withCredentials: true,
});

export const getAccessTokenFromRefresh = async (
  refreshToken: string
): Promise<AxiosResponse<any>> => {
  const data = await authAxios.post(
    `/token?refresh_token=${refreshToken}&grant_type=refresh_token&redirect_uri=${redirectUri}&client_id=${clientId}&client_secret=${clientSecret}`
  );

  return data;
};

export const getAccessToken = async (
  code: string
): Promise<AxiosResponse<any>> => {
  return authAxios.post(
    `/token?code=${code}&grant_type=authorization_code&redirect_uri=${redirectUri}&client_id=${clientId}&client_secret=${clientSecret}`
  );
};

export const userMe = async (user: string): Promise<AxiosResponse<any>> => {
  return communication.axios.get(`/cloud/users/${user}`);
};

export const notifications = async (): Promise<AxiosResponse<any>> => {
  return communication.axios.get(`/apps/notifications/api/v2/notifications`);
};

export const deleteNotification = async (
  id: number
): Promise<AxiosResponse<any>> => {
  return communication.axios.delete(
    `/apps/notifications/api/v2/notifications/${id}`
  );
};

export const capabilitiesGet = async (): Promise<AxiosResponse<any>> => {
  return communication.axios.get(`/cloud/capabilities`);
};

export const fetchFavourites = async (wholePath: string): Promise<Response> => {
  return communication.webDavClientWrapper.customRequest(
    wholePath,
    getCustomRequestParams(XMLRequestEnum.FavouritesFetch)
  );
};

export const postFavourite = async (
  path: string,
  isFav: 0 | 1
): Promise<Response> => {
  return communication.webDavClientWrapper.customRequest(
    path,
    getCustomRequestParams(XMLRequestEnum.FavouritesPost, 1, isFav)
  );
};

export const fetchFoldersXML = async (
  wholePath: string,
  depth: number
): Promise<Response> => {
  return communication.webDavClientWrapper.customRequest(
    wholePath,
    getCustomRequestParams(XMLRequestEnum.FoldersFetch, depth)
  );
};

export const fileInfoPost = async (
  paths: string[],
  withPresentaion: boolean
): Promise<AxiosResponse<any>> => {
  const params = new URLSearchParams();
  params.append("paths", JSON.stringify(paths));
  params.append("withPresentations", JSON.stringify(withPresentaion));
  return communication.axios.post(`/apps/rsam/api/v1/files/list`, params);
};

export const getSharedFiles = (): Promise<AxiosResponse<any>> => {
  return communication.axios.get(
    "/apps/files_sharing/api/v1/shares?shareType=3"
  );
};

export const getSharedFile = (path: string): Promise<AxiosResponse<any>> => {
  return communication.axios.get(
    `/apps/files_sharing/api/v1/shares?path=${path}`
  );
};

export const postSharedFile = (path: string): Promise<AxiosResponse<any>> => {
  const params = new URLSearchParams();
  params.append("shareType", "3");
  params.append("path", path);
  params.append("permissions", "1");
  params.append("publicUpload", "false");
  return communication.axios.post("/apps/files_sharing/api/v1/shares", params);
};

export const postFileByWebDav = async (
  path: string,
  file: string
): Promise<boolean> => {
  return communication.webDavClientWrapper.putFileContents(path, file);
};

export const logUserOut = async (): Promise<AxiosResponse<any>> => {
  return communication.axios.get(
    `/apps/rsam/api/v1/logout?redirect_url=${encodeURIComponent(
      `${window.location.origin}/tools`
    )}`
  );
};
