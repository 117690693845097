/** @jsx jsx */
import { FC, useState, useEffect } from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";
import "./App.css";
import "video-react/dist/video-react.css";
import { Switch, Route, Redirect } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { TopBar } from "./components/shared/TopBar";
import { routes } from "./router";
import { TOP_BAR_HEIGHT } from "./styles/dimensions";
import { RoutePaths } from "./helpers/enums/RoutePaths";
import { DrawerMenu } from "./components/shared/DrawerMenu";
import {
  Notification,
  SnackbarInterface,
} from "./helpers/interfaces/Notification";
import { notificationDownloadTimer } from "./helpers/constants/common";
import { LoadingState } from "./helpers/enums/general";
import { ShareLinkSnackbar } from "./components/shared/CopySnackbar";
import { AppSnackbar } from "./components/shared/AppSnackbar";
import { File } from "./helpers/interfaces/file";
import { PageTitle } from "./components/shared/PageTitle";
import { renderNoUser } from "./helpers/functions/jsxHelpers";
import { PptSlideShareRefs } from "./helpers/interfaces/pptSlides";
import { fetchRefreshToken } from "./store/general/actions";
import { getRefreshToken } from "./helpers/functions/localstorageHelpers";

export interface DispatchProps {
  onGetNotifications: () => void;
  fetchRefreshToken: typeof fetchRefreshToken;
}

export interface StateProps {
  notifications: Notification[];
  notificationUnreadCount: number;
  notificationsAllowed: boolean;
  userLoggedIn: boolean;
  shareLinkState: LoadingState;
  shareLinks: string[];
  folders: File[];
  folderLoadState: LoadingState;
  parent: File | undefined;
  snack: SnackbarInterface | undefined;
  downloadState: LoadingState;
  currentSlide: PptSlideShareRefs | undefined;
}

type Props = DispatchProps & StateProps;

export const App: FC<Props> = ({
  onGetNotifications,
  notificationUnreadCount,
  userLoggedIn,
  shareLinks,
  folders,
  parent,
  notificationsAllowed,
  snack,
  downloadState,
  currentSlide,
  shareLinkState,
  folderLoadState,
  fetchRefreshToken,
}: Props) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const [availableRoutes, setAvailableRoutes] = useState(routes);
  const [forbiddenRoutes, setForbiddenRoutes] = useState<any>([]);

  useEffect(() => {
    if (userLoggedIn && notificationsAllowed) {
      setTimeout(() => {
        onGetNotifications();
      }, 1000);
    }
  }, [notificationsAllowed, onGetNotifications, userLoggedIn]);

  useEffect(() => {
    if (!userLoggedIn) {
      const availableRoutes = routes.filter((r) => r.authRoute !== true);
      const forbidden = routes.filter(
        (r) => r.authRoute && r.authRoute === true
      );
      setAvailableRoutes(availableRoutes);
      setForbiddenRoutes(forbidden);
    }
  }, [userLoggedIn]);

  useEffect(() => {
    const refreshToken = getRefreshToken();

    if (refreshToken) {
      fetchRefreshToken(refreshToken);
    }
  }, [fetchRefreshToken]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (userLoggedIn && notificationsAllowed) {
      interval = setInterval(() => {
        onGetNotifications();
      }, notificationDownloadTimer);

      return (): void => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }

    if (!notificationsAllowed && interval) {
      clearInterval(interval);
    }
    if (!userLoggedIn && interval) {
      clearInterval(interval);
    }
  }, [notificationsAllowed, onGetNotifications, userLoggedIn]);

  return (
    <div css={appContainerCss}>
      <TopBar
        folders={folders}
        parentFolder={parent}
        userLoggedIn={userLoggedIn}
        downloadState={downloadState}
        shareLinkState={shareLinkState}
        notificationUnreadCount={notificationUnreadCount}
        currentSlide={currentSlide}
        folderLoadState={folderLoadState}
        changeDrawerVisibility={(): void => {
          setIsDrawerVisible(!isDrawerVisible);
        }}
      />
      <Grid container direction="row" css={wrapCss}>
        <DrawerMenu
          isVisible={isDrawerVisible}
          closeDrawer={(): void => {
            setIsDrawerVisible(false);
          }}
        />
        <Grid item xs={12} md={9} lg={10} xl={11} css={bodyWrapCss}>
          <Switch>
            <Redirect exact from="/" to={`${RoutePaths.PRODUCTS}`} />
            {availableRoutes.map((route) => (
              <Route key={route.path} path={route.path} {...route.props} />
            ))}
            {forbiddenRoutes.map((route: any) => (
              <Route key={route.path} path={route.path} {...route.props}>
                {renderNoUser()}
              </Route>
            ))}
            <Route path="*">
              <PageTitle title={<Trans>Page not found</Trans>} centerTitle />
            </Route>
          </Switch>
        </Grid>
        <ShareLinkSnackbar
          openSnack={shareLinkState === LoadingState.Success}
          shareLinks={shareLinks}
        />
        <AppSnackbar snack={snack} />
      </Grid>
    </div>
  );
};

const appContainerCss: SerializedStyles = css`
  height: 100vh;
`;
const wrapCss: SerializedStyles = css`
  height: calc(100% - ${Math.round(TOP_BAR_HEIGHT)}px) !important;
  overflow: hidden;
  position: relative;
`;
const bodyWrapCss: SerializedStyles = css`
  height: 100% !important;
  overflow: auto;
`;
