export enum WebDavFileType {
  DIRECTORY = "directory",
  FILE = "file",
}

export enum FileType {
  PPT = "fibula",
  PDF = "pdf",
  IMAGE = "image",
  VIDEO = "video",
  DOCUMENT = "document",
  NOTE = "note",
  UNSPECIFIED = "unspecified",
}
