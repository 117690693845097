import { FileType } from "../enums/file";
import { MimeMetaData } from "../interfaces/general";
import { VideoDetail } from "../../components/file/Video";
import { ImageDetail } from "../../components/file/Image";
import { PdfDetail } from "../../components/file/Pdf";
import { MimeType } from "../enums/mimeType";
import NewNoteContainer from "../../pages/tools/NewNoteContainer";

/* eslint-disable global-require */
export const icons = {
  video: require("../../assets/file-type-icons/video-icon.png"),
  directory: require("../../assets/file-type-icons/folder-net-icon.png"),
  excel: require("../../assets/file-type-icons/excel-icon.png"),
  note: require("../../assets/file-type-icons/note-icon.png"),
  pdf: require("../../assets/file-type-icons/pdf-icon.png"),
  image: require("../../assets/file-type-icons/photo-icon.png"),
  ppt: require("../../assets/file-type-icons/ppt-icon.png"),
  word: require("../../assets/file-type-icons/word-icon.png"),
  unsupported: require("../../assets/file-type-icons/unsupported.png"),
};
/* eslint-enable global-require */

export const fileMapper: {
  [key: string]: MimeMetaData;
} = {
  [MimeType.Video]: {
    component: VideoDetail,
    type: FileType.VIDEO,
    icon: icons.video,
  },
  [MimeType.ImagePng]: {
    component: ImageDetail,
    type: FileType.IMAGE,
    icon: icons.image,
  },
  [MimeType.ImageJpg]: {
    component: ImageDetail,
    type: FileType.IMAGE,
    icon: icons.image,
  },
  [MimeType.ImageJpeg]: {
    component: ImageDetail,
    type: FileType.IMAGE,
    icon: icons.image,
  },
  [MimeType.Sheet]: {
    type: FileType.DOCUMENT,
    icon: icons.excel,
  },
  [MimeType.SheetX]: {
    type: FileType.DOCUMENT,
    icon: icons.excel,
  },
  [MimeType.Document]: {
    type: FileType.DOCUMENT,
    icon: icons.word,
  },
  [MimeType.DocumentX]: {
    type: FileType.DOCUMENT,
    icon: icons.word,
  },
  [MimeType.Presentation]: {
    type: FileType.DOCUMENT,
    icon: icons.ppt,
  },
  [MimeType.PresentationX]: {
    type: FileType.DOCUMENT,
    icon: icons.ppt,
  },
  [MimeType.Text]: {
    type: FileType.DOCUMENT,
    icon: icons.unsupported,
  },
  [MimeType.Markdown]: {
    type: FileType.DOCUMENT,
    icon: icons.unsupported,
  },
  [MimeType.Pdf]: {
    component: PdfDetail,
    type: FileType.PDF,
    icon: icons.pdf,
  },
  [MimeType.Note]: {
    component: NewNoteContainer,
    type: FileType.NOTE,
    icon: icons.note,
  },
};

export const FIRST_FIBULA_THUMBNAIL = "slide01-x300.png";
export const FIBULA_DEFAULT_ASPECTR = 4 / 3;
