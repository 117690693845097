import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { State } from "../../store/combinedReducers";
import { NewNote, StateProps, DispatchProps } from "./NewNote";
import { uploadJSONFile } from "../../store/general/actions";

const mapStateToProps = (state: State): StateProps => ({
  note: state.general.note,
  loadingState: state.general.fileUploadState,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  ...bindActionCreators(
    {
      uploadJSONFile,
    },
    dispatch
  ),
});

const NewNoteContainer = connect(mapStateToProps, mapDispatchToProps)(NewNote);

export default NewNoteContainer;
