import { createMuiTheme } from "@material-ui/core/styles";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";
import { BreakpointValues } from "@material-ui/core/styles/createBreakpoints";
import { ColorsInterface } from "../helpers/interfaces/colors";

export const Colors: ColorsInterface = {
  primary: "#626262",
  secondary: "#C0D12A",
  white: "#fff",
  black: "#000000",
  darkGrey: "#666666",
  gray: "#959595",
  gray2: "#D8D8D8",
  gray3: "#ABABAB",
  gray4: "#F2F2F2",
  lightGrey: "#EBEBEB",
  dark: "#232323",
  pageTitleBG: "#F5F5F5",
  tabBar: "#F7F7F7",
  green: "#1C5262",
  red: "#da161c",
};

const palette: PaletteOptions = {
  primary: { main: Colors.primary, contrastText: Colors.black },
  secondary: { main: Colors.secondary, contrastText: Colors.black },
  text: {
    primary: Colors.dark,
  },
};

const typography: TypographyOptions = {
  h5: {
    fontWeight: 500,
  },
};

interface CustomBreakpoints {
  values: BreakpointValues;
}
const breakpoints: CustomBreakpoints = {
  values: {
    xs: 0,
    sm: 800,
    md: 1026,
    lg: 1280,
    xl: 1920,
  },
};

export const APP_THEME = createMuiTheme({
  palette,
  typography,
  breakpoints,
});

// Multiplies of this constants are used for margins or paddings within this app
export const MPX = window.innerWidth > breakpoints.values.md ? 3 : 4;
