import { i18nMark } from "@lingui/core";
import { LocalStorageKeys } from "../enums/storage";

export enum AppLanguages {
  EN = "en",
  ZH = "zh",
}

// Global translations
i18nMark("Home");
i18nMark("Proof");

/**
 * if localStorage contains LANGUAGE key set it as LANGUAGE else use browser language
 *
 * if AppLanguages contains browser language set it as LANGUAGE else use default app language (english)
 */

const getStorageLanguage = window.localStorage.getItem(
  LocalStorageKeys.LANGUAGE
);

const storageLanguage = Object.values(AppLanguages).find(
  (item) => item === getStorageLanguage
);

const browserLanguage = !storageLanguage
  ? window.navigator.language.substring(0, 2)
  : storageLanguage;

const language = Object.values(AppLanguages).find(
  (item) => item === browserLanguage
);

const DEFAULT_APP_LANGUAGE = AppLanguages.EN;

export const LANGUAGE = !language ? DEFAULT_APP_LANGUAGE : language;
