/** @jsx jsx */

import React, { FC, useMemo } from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useHistory, Link, useLocation } from "react-router-dom";
import { Hidden, IconButton, Grid, CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { TOP_BAR_HEIGHT } from "../../styles/dimensions";
import { Colors, MPX } from "../../styles/themes";
import { RoutePaths } from "../../helpers/enums/RoutePaths";
import { getQueryParams } from "../../helpers/functions/pathHelper";
import { QueryParams } from "../../helpers/interfaces/queryParam";
import { DirectoryType } from "../../helpers/enums/dirType";
import { File } from "../../helpers/interfaces/file";
import {
  markAsFavourite,
  getShareLink,
  handleDownloadFile,
} from "../../store/general/actions";
import { LoadingState } from "../../helpers/enums/general";
import { PptSlideShareRefs } from "../../helpers/interfaces/pptSlides";

/* eslint-disable global-require */
const images = {
  logo: require("../../assets/logo/rieter.png"),
  download: require("../../assets/images/download-white.png"),
  share: require("../../assets/images/share-white.png"),
  star: require("../../assets/images/star-white.png"),
  starFilled: require("../../assets/images/star-white-filled.png"),
  bell: require("../../assets/images/bell.png"),
  bellActive: require("../../assets/images/bell-active.png"),
  brackerLogo: require("../../assets/logo/bracker.png"),
};
/* eslint-enable global-require */

interface TopBarProps {
  changeDrawerVisibility: () => void;
  notificationUnreadCount: number;
  userLoggedIn: boolean;
  folders: File[];
  parentFolder: File | undefined;
  downloadState: LoadingState;
  shareLinkState: LoadingState;
  currentSlide: PptSlideShareRefs | undefined;
  folderLoadState: LoadingState;
}

export const TopBar: FC<TopBarProps> = ({
  changeDrawerVisibility,
  notificationUnreadCount,
  userLoggedIn,
  folders,
  parentFolder,
  downloadState,
  currentSlide,
  shareLinkState,
  folderLoadState,
}: TopBarProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const queryParams: QueryParams[] = getQueryParams(history.location.search);

  const isFilePage = queryParams.find(
    (param) => param.key === DirectoryType.FILE
  );
  const isPptFilePage = queryParams.find(
    (param) => param.key === DirectoryType.PPT
  );
  const getSelectedFile = (): File | undefined => {
    if (isFilePage) {
      return folders.length > 0 ? folders[0] : undefined;
    }
    if (isPptFilePage) {
      return parentFolder;
    }
    return undefined;
  };

  const selectedFile = getSelectedFile();

  const handleDownloadBtn = async (): Promise<void> => {
    // it we are in Fibula folder then download whole generated PDF with whole fibula
    const fibulaNames = {
      filename: `${parentFolder?.filename}presentation.pdf`,
      basename: `${selectedFile?.basename}.pdf`,
    };
    const file = isPptFilePage ? fibulaNames : selectedFile;

    if (selectedFile) {
      dispatch(handleDownloadFile(file?.filename, file?.basename));
    }
  };

  const handleStarBtn = async (): Promise<void> => {
    if (selectedFile) {
      dispatch(
        markAsFavourite(selectedFile.filename, selectedFile.isFavourite)
      );
    }
  };

  const handleShareBtn = async (): Promise<void> => {
    if (currentSlide && isPptFilePage) {
      dispatch(
        getShareLink([currentSlide.videoResource || currentSlide.pdfResource])
      );
    } else if (selectedFile) {
      dispatch(getShareLink([selectedFile.filename]));
    }
  };

  const handleMenu = (): void => {
    changeDrawerVisibility();
  };

  const isLoading = folderLoadState === LoadingState.Loading;

  const isSpinnApp = useMemo(() => {
    if (pathname.includes("/apps/spinn-data")) {
      return true;
    }
    return false;
  }, [pathname]);

  return (
    <Grid
      container
      css={containerCss(isSpinnApp ? Colors.red : Colors.secondary)}
    >
      <Grid item css={[headerPartCss, headerCenterPartCss]}>
        <Grid item css={[headerPartCss, headerLeftPartCss]}>
          {/*  TODO - maybe will be added back
        <IconButton
          size="small"
          css={iconButtonCss}
          onClick={handleBackButton}
          disabled={disabled}
        >
          <img src={images.arrow} alt="back" css={arrowImgCss} />
        </IconButton> */}
          <Hidden mdUp>
            <IconButton css={[menuCss, iconButtonCss]} onClick={handleMenu}>
              <MenuIcon style={{ color: "white" }} fontSize="large" />
            </IconButton>
          </Hidden>
        </Grid>
        <Grid item css={headerPartCss}>
          <img
            src={isSpinnApp ? images.brackerLogo : images.logo}
            alt="Rieter"
            height={isSpinnApp ? 25 : 35}
          />
        </Grid>

        <Grid item css={[headerPartCss, headerRightPartCss]}>
          {isFilePage || isPptFilePage ? (
            <React.Fragment>
              {downloadState !== LoadingState.Loading ? (
                <IconButton
                  css={[headerRightCss, iconButtonCss]}
                  onClick={handleDownloadBtn}
                  disabled={isLoading}
                >
                  <img src={images.download} alt="download" height={23} />
                </IconButton>
              ) : (
                <CircularProgress css={loaderCss} size={23} />
              )}
              {shareLinkState !== LoadingState.Loading ? (
                <IconButton
                  css={[headerRightCss, iconButtonCss]}
                  onClick={handleShareBtn}
                  disabled={isLoading || (!currentSlide && !!isPptFilePage)}
                >
                  <img src={images.share} alt="share" height={23} />
                </IconButton>
              ) : (
                <CircularProgress css={loaderCss} size={23} />
              )}

              {userLoggedIn && (
                <IconButton
                  css={[headerRightCss, iconButtonCss]}
                  onClick={handleStarBtn}
                  disabled={isLoading}
                >
                  <img
                    src={
                      selectedFile?.isFavourite
                        ? images.starFilled
                        : images.star
                    }
                    height={23}
                    alt="star"
                  />
                </IconButton>
              )}
            </React.Fragment>
          ) : (
            <Grid item />
          )}

          {userLoggedIn ? (
            <Link to={`/${RoutePaths.NOTIFICATIONS}`}>
              <IconButton css={[headerRightCss, iconButtonCss]}>
                {notificationUnreadCount > 0 ? (
                  <img src={images.bellActive} alt="bell active" height={23} />
                ) : (
                  <img src={images.bell} alt="bell" height={23} />
                )}
              </IconButton>
            </Link>
          ) : (
            <div />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
const iconButtonCss: SerializedStyles = css`
  height: ${MPX * 10}px !important;
  width: ${MPX * 10}px !important;
  padding: 0 !important;
`;
const containerCss = (color: string): SerializedStyles => css`
  flex: 1;
  height: ${TOP_BAR_HEIGHT}px;
  background-color: ${color};
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const headerPartCss: SerializedStyles = css`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const headerLeftPartCss: SerializedStyles = css`
  justify-content: flex-start;
`;
const headerCenterPartCss: SerializedStyles = css`
  justify-content: center;
`;
const headerRightPartCss: SerializedStyles = css`
  justify-content: flex-end;
`;
const menuCss: SerializedStyles = css`
  margin-left: ${MPX * 5}px !important;
`;
const headerRightCss: SerializedStyles = css`
  margin-right: ${MPX * 2}px !important;
  cursor: pointer;
`;

const loaderCss: SerializedStyles = css`
  margin-right: ${MPX * 4}px !important;
  margin-left: ${MPX * 2}px !important;
  padding: 0 !important;
  color: white !important;
`;
