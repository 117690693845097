import { User } from "../interfaces/file";
import { guestName } from "./apiCall";
import { Notification } from "../interfaces/Notification";

export const setPercentage = (percentage: number, from: number): number => {
  return (from / 100) * percentage;
};

export const getUsername = (loggedUser?: User): string => {
  return loggedUser ? loggedUser.id : guestName;
};

export const removeDuplicateNotifications = (
  items: Notification[]
): Notification[] => {
  const changedItems = items.filter(
    (thing, index, self) =>
      index ===
      self.findIndex((t) => t.notification_id === thing.notification_id)
  );

  return changedItems;
};

export const copyCodeToClipboard = (shareLink: string): void => {
  if (shareLink) {
    const el = document.createElement("textarea");
    el.value = shareLink;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-99999";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }
};
