import * as Sentry from "@sentry/browser";
import { checkSlashAtEnd, getDataPathFromFilename } from "./pathHelper";
import { SharedFile } from "../interfaces/sharedFile";
import { postSharedFile, getSharedFiles, getSharedFile } from "./apiCall";
import { File } from "../interfaces/file";

export const getShareFileLink = async (
  filenames: string[]
): Promise<string[] | undefined> => {
  const result: string[] = [];
  const pathsToShare: string[] = [];
  const paths = filenames.map((filename) => getDataPathFromFilename(filename));
  let isValid = true;

  const response = await getSharedFiles();
  if (response.status === 200) {
    const userSharedLinks: SharedFile[] = response.data.ocs.data;

    paths.forEach((p) => {
      const link = userSharedLinks.find((l) => l.path === p);

      if (link) {
        result.push(link.url);
      } else {
        pathsToShare.push(p);
      }
    });

    if (pathsToShare.length > 0) {
      const responses = await Promise.all<string>(
        pathsToShare.map(async (p) => {
          const shareFileRespose = await postSharedFile(p);

          if (shareFileRespose.status === 200) {
            return shareFileRespose.data.ocs.data.url;
          }
          isValid = false;

          return undefined;
        })
      );

      if (!isValid) {
        return undefined;
      }
      return [...result, ...responses];
    }

    return result;
  }

  return undefined;
};

export const getShareLinkForPpt = async (
  path: string
): Promise<string | undefined> => {
  try {
    const response = await getSharedFile(getDataPathFromFilename(path));
    const userSharedLink = response.data?.ocs?.data[0];

    if (!userSharedLink) {
      const postShare = await postSharedFile(getDataPathFromFilename(path));

      return postShare.data?.ocs?.data?.url;
    }

    return userSharedLink.url;
  } catch (error) {
    Sentry.captureException(error);
    return undefined;
  }
};

// sets presentation.pdf as shared or downloaded file for fibula
export const isFibulaFolder = (file: File): string => {
  return file.info?.presentation
    ? `${checkSlashAtEnd(file.filename)}presentation.pdf`
    : file.filename;
};
