/** @jsx jsx */

import React, { FC, useState, useEffect } from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";
import { Trans } from "@lingui/macro";
import {
  FormControl,
  TextField,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { titleCss } from "../../components/shared/PageTitle";
import { MPX, Colors } from "../../styles/themes";
import { Note } from "../../helpers/interfaces/note";
import { initNote } from "../../helpers/functions/noteAndUpload";
import { TITLE_HEIGHT } from "../../styles/dimensions";
import { LoadingState } from "../../helpers/enums/general";
import { setNote } from "../../store/general/actions";

/* eslint-disable global-require */
const images = {
  camera: require("../../assets/images/camera.png"),
  arrow: require("../../assets/images/arrow-right-yellow.png"),
};
/* eslint-enable global-require */

export interface DispatchProps {
  uploadJSONFile: (filename: string, file: string) => void;
}
export interface StateProps {
  note: Note | undefined;
  loadingState: LoadingState;
}

type NewNoteProps = StateProps & DispatchProps;

export const NewNote: FC<NewNoteProps> = ({
  note,
  uploadJSONFile,
  loadingState,
}) => {
  const [updatedNote, setUpdatedNote] = useState<Note>(note || initNote);
  const [validated, setValidated] = useState(false);
  const [isOld, setIsOld] = useState(!!note);
  const dispatch = useDispatch();

  useEffect(() => {
    if (updatedNote.title !== note?.title || !note) {
      setIsOld(false);
    } else {
      setIsOld(true);
    }
  }, [note, updatedNote.title, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setNote({ note: undefined }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkTitleValidity = (title: string): boolean => {
    return /^[\w-]+$/.test(title);
  };

  const handleSaveButton = (): void => {
    const jsonedNote = JSON.stringify(updatedNote);
    uploadJSONFile(updatedNote.title, jsonedNote);
  };

  const checkValidity = (noteObj: Note): boolean => {
    return (
      (!!noteObj.base64string ||
        !!noteObj.company ||
        !!noteObj.date ||
        !!noteObj.email ||
        !!noteObj.fullName ||
        !!noteObj.note ||
        !!noteObj.phone) &&
      !!checkTitleValidity(noteObj.title)
    );
  };

  const handleNoteUpdate = (value: string, param: string): void => {
    if (updatedNote) {
      const updated: Note = { ...updatedNote, [param]: value };
      setUpdatedNote(updated);
      setValidated(checkValidity(updated));
    }
  };

  const handlePhotoButton = (event: any): void => {
    const value = event.target.files[0];
    if (value) {
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onloadend = (): void => {
        const base64data = reader.result as string;
        const updated: Note = {
          ...updatedNote,
          base64string: base64data,
        };
        setUpdatedNote(updated);
        setValidated(checkValidity(updated));
      };
    }
  };

  const renderPhotoButton = (): JSX.Element => (
    <React.Fragment>
      <label htmlFor="upload-file">
        <input
          type="file"
          id="upload-file"
          name="uploadedFiles"
          onChange={handlePhotoButton}
          css={buttonInputCss}
        />
        <Button
          component="span"
          css={photoButtonCss}
          startIcon={<img css={cameraIconCss} src={images.camera} alt="--" />}
        >
          <div css={photoButtonTextCss}>
            <Trans>Select a photo</Trans>
          </div>
        </Button>
      </label>
    </React.Fragment>
  );
  const renderSaveButton = (): JSX.Element => (
    <div css={saveButtonWrapCss}>
      <Button
        css={saveButtonCss(!validated)}
        onClick={handleSaveButton}
        disabled={!validated}
      >
        {loadingState === LoadingState.Loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <div css={saveButtonTextCss}>
            {!isOld ? <Trans>Create note</Trans> : <Trans>Update note</Trans>}
          </div>
        )}

        <img src={images.arrow} alt="-->" />
      </Button>
    </div>
  );
  const renderForm = (): JSX.Element => (
    <FormControl css={formCss}>
      <div css={inputWrapCss}>
        <TextField
          css={inputCss}
          variant="outlined"
          label={<Trans>First name and Last name</Trans>}
          fullWidth
          value={updatedNote.fullName}
          onChange={(e): void => handleNoteUpdate(e.target.value, "fullName")}
        />
      </div>
      <div css={inputWrapCss}>
        <TextField
          css={inputCss}
          variant="outlined"
          label={<Trans>E-mail</Trans>}
          fullWidth
          value={updatedNote.email}
          onChange={(e): void => handleNoteUpdate(e.target.value, "email")}
        />
      </div>
      <div css={inputWrapCss}>
        <TextField
          css={inputCss}
          variant="outlined"
          label={<Trans>Phone</Trans>}
          fullWidth
          value={updatedNote.phone}
          onChange={(e): void => handleNoteUpdate(e.target.value, "phone")}
        />
      </div>
      <div css={inputWrapCss}>
        <TextField
          css={inputCss}
          variant="outlined"
          label={<Trans>Company</Trans>}
          fullWidth
          value={updatedNote.company}
          onChange={(e): void => handleNoteUpdate(e.target.value, "company")}
        />
      </div>
      <div css={inputWrapCss}>
        <TextField
          css={inputCss}
          variant="outlined"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          label={<Trans>Date</Trans>}
          fullWidth
          value={updatedNote.date}
          onChange={(e): void => handleNoteUpdate(e.target.value, "date")}
        />
      </div>
      <div css={inputWrapCss}>
        <TextField
          css={inputCss}
          variant="outlined"
          label={<Trans>Notes</Trans>}
          fullWidth
          multiline
          rows={6}
          value={updatedNote.note}
          onChange={(e): void => handleNoteUpdate(e.target.value, "note")}
        />
      </div>
    </FormControl>
  );
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item sm={8} md={7} lg={5} xl={4}>
        <div css={titleHeightCss}>
          <TextField
            css={titleInputCss}
            fullWidth
            value={updatedNote.title}
            onChange={(e): void => handleNoteUpdate(e.target.value, "title")}
            InputProps={{ disableUnderline: true }}
          />
        </div>
        {renderForm()}
        <Grid container justify="center" alignItems="center">
          <Grid item>
            {updatedNote.base64string && (
              <img
                src={updatedNote.base64string}
                alt="uploaded-img"
                css={imagePreviewCss}
              />
            )}
          </Grid>
        </Grid>
        {renderPhotoButton()}
        {renderSaveButton()}
      </Grid>
    </Grid>
  );
};

const formCss: SerializedStyles = css`
  width: 100% !important;
  padding-top: ${MPX * 5}px !important;
`;

const inputWrapCss: SerializedStyles = css`
  padding-top: ${MPX * 5}px !important;
`;
const inputCss: SerializedStyles = css`
  .MuiOutlinedInput-root {
    border-radius: 0 !important;
    border-color: ${Colors.black} !important;
  }
`;

const titleHeightCss = css`
  height: ${TITLE_HEIGHT}px;
  align-items: flex-end;
  display: flex;
`;

const titleInputCss = css`
  .MuiInput-input {
    ${titleCss(Colors.black)}
    text-align: center !important;
    padding: 0px !important;
  }
`;

const buttonInputCss: SerializedStyles = css`
  display: none;
`;
const photoButtonCss: SerializedStyles = css`
  margin-top: ${MPX * 6}px !important;
  border-radius: 8px;
  height: ${MPX * 13.75}px;
  width: 100%;
  background-color: ${Colors.lightGrey} !important;
`;

const cameraIconCss: SerializedStyles = css`
  padding-right: ${MPX * 5}px !important;
`;
const photoButtonTextCss: SerializedStyles = css`
  font-size: ${MPX * 4}px;
  color: ${Colors.primary};
  text-transform: initial;
`;

const saveButtonWrapCss: SerializedStyles = css`
  margin-top: ${MPX * 8}px;
  margin-bottom: ${MPX * 5}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const saveButtonCss = (disabled: boolean): SerializedStyles => css`
  padding-left: ${MPX * 5}px !important;
  padding-right: ${MPX * 5}px !important;
  border-radius: 0 !important;
  display: flex !important;
  justify-content: space-between !important;
  height: ${MPX * 18}px !important;
  width: 50%;
  background-color: ${!disabled ? Colors.dark : Colors.lightGrey} !important;
`;

const saveButtonTextCss: SerializedStyles = css`
  font-size: ${MPX * 4}px;
  color: ${Colors.white};
  text-transform: uppercase;
`;

const imagePreviewCss = css`
  max-height: ${MPX * 37.5}px !important;
  max-width: 100% !important;
  margin-top: ${MPX * 4}px;
`;
