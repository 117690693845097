import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { State } from "./store/combinedReducers";
import { App, StateProps, DispatchProps } from "./App";
import { getNotifications, fetchRefreshToken } from "./store/general/actions";
import {
  notificationSelector,
  notificationUnreadCountSelector,
} from "./store/general/selectors";

const mapStateToProps = (state: State): StateProps => ({
  notifications: notificationSelector(state),
  notificationUnreadCount: notificationUnreadCountSelector(state),
  notificationsAllowed: !!state.general.notificationsAllowed,
  userLoggedIn: !!state.general.loggedUser,
  shareLinkState: state.general.shareLinkState,
  shareLinks: state.general.shareLinks,
  folders: state.general.folders,
  folderLoadState: state.general.folderLoadState,
  parent: state.general.parent,
  snack: state.general.snack,
  downloadState: state.general.downloadState,
  currentSlide: state.general.currentSlide,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  ...bindActionCreators(
    {
      onGetNotifications: getNotifications,
      fetchRefreshToken,
    },
    dispatch
  ),
});

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
