import { Action, ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { action, payload } from "ts-action";
import * as xmlJs from "xml-js";
import { set } from "js-cookie";
import * as Sentry from "@sentry/browser";
import { i18nMark } from "@lingui/core";
import {
  userMe,
  communication,
  webDavFactory,
  fileInfoPost,
  fetchFoldersXML,
  fetchFavourites,
  getAccessToken,
  axiosFactory,
  postFavourite,
  notifications,
  postFileByWebDav,
  deleteNotification,
  getAccessTokenFromRefresh,
} from "../../helpers/functions/apiCall";
import { State } from "../combinedReducers";
import {
  isPrivateFile,
  adjustFileWithMetaData,
  mapJSONToFolderData,
  fetchNoteContent,
  separateParent,
  createDownloadUrl,
} from "../../helpers/functions/fileHelpers";
import { getDataPathFromFilename } from "../../helpers/functions/pathHelper";
import { LoadingState } from "../../helpers/enums/general";
import { File, User } from "../../helpers/interfaces/file";
import { AppLanguages } from "../../helpers/constants/languages";
import { LocalStorageKeys } from "../../helpers/enums/storage";
import { FileInfo } from "../../helpers/interfaces/fileInfo";
import {
  NotifBase,
  Notification,
  SnackbarInterface,
} from "../../helpers/interfaces/Notification";
import { getUsername } from "../../helpers/functions/commons";
import {
  TOKEN_COOKIE_NAME,
  TOKEN_COOKIE_EXPIRATION_IN_DAYS,
  USER_COOKIE_NAME,
  LOCALSTORAGE_NOTIFICATIONS_ALLOWED,
  removeAllCookies,
} from "../../helpers/constants/cookie";
import { getShareFileLink } from "../../helpers/functions/shareHelper";
import { Note } from "../../helpers/interfaces/note";
import { noteExt } from "../../helpers/enums/mimeType";
import {
  redirectUrlForHref,
  redirectUrlForLogoutHref,
} from "../../helpers/constants/common";
import { PptSlideShareRefs } from "../../helpers/interfaces/pptSlides";
import { parseNotificationLink } from "../../helpers/functions/notificationHelper";

export enum ActionTypes {
  SET_LANGUAGE = "[general] SET_LANGUAGE",
  SET_REQUEST_STATE = "[general] SET_REQUEST_STATE",
  SET_FILE_FETCH_STATE = "[general] SET_FILE_FETCH_STATE",
  SET_LOGGED_USER = "[general] SET_LOGGED_USER",
  LOG_OUT = "[general] LOG_OUT",
  SET_FOLDERS = "[general] SET_FOLDERS",
  SET_PARENT = "[general] SET_PARENT",
  SET_FAVOURITES = "[general] SET_FAVOURITES",
  SET_FAVOURITES_REQUEST_STATE = "[general] SET_FAVOURITES_REQUEST_STATE",
  SET_NOTIFICATION = "[general] SET_NOTIFICATION",
  SET_FILE_UPLOAD_STATE = "[general] SET_FILE_UPLOAD_STATE",
  SET_NOTE = "[general] SET_NOTE",
  SET_NOTIFICATION_ALLOWED = "[general] SET_NOTIFICATION_ALLOWED",
  MARK_NOTIFICATION_AS_READ = "[general] MARK_NOTIFICATION_AS_READ",
  ADD_TO_READ_NOTIFICATIONS = "[general] ADD_TO_READ_NOTIFICATIONS",
  SET_SHARE_LINK_STATE = "[general] SET_SHARE_LINK_STATE",
  SET_SHARE_LINK = "[general] SET_SHARE_LINK",
  APP_SNACK_MESSAGE = "[general] APP_SNACK_MESSAGE",
  SET_DOWNLOAD_FILE_STATE = "[general] SET_DOWNLOAD_FILE_STATE",
  SET_PARENT_FAVOURITES = "[general] SET_PARENT_FAVOURITES",
  SET_CURRENT_SLIDE = "[general] SET_CURRENT_SLIDE",
  SET_NOTIFICATION_STATE = "[general] SET_NOTIFICATION_STATE",
}

export const setLanguage = action(
  ActionTypes.SET_LANGUAGE,
  payload<{ language: AppLanguages }>()
);

export const setRequestState = action(
  ActionTypes.SET_REQUEST_STATE,
  payload<{ loadState: LoadingState }>()
);

export const setNotificationState = action(
  ActionTypes.SET_NOTIFICATION_STATE,
  payload<{ notificationLoadState: LoadingState }>()
);

export const setDownloadFileState = action(
  ActionTypes.SET_DOWNLOAD_FILE_STATE,
  payload<{ loadState: LoadingState }>()
);

export const setSnack = action(
  ActionTypes.APP_SNACK_MESSAGE,
  payload<{ snack: SnackbarInterface | undefined }>()
);

export const setUser = action(
  ActionTypes.SET_LOGGED_USER,
  payload<{ user: User | undefined }>()
);

export const setFolderFetchState = action(
  ActionTypes.SET_FILE_FETCH_STATE,
  payload<{ folderLoadState: LoadingState }>()
);

export const setNote = action(
  ActionTypes.SET_NOTE,
  payload<{ note: Note | undefined }>()
);

export const setFileUploadState = action(
  ActionTypes.SET_FILE_UPLOAD_STATE,
  payload<{ fileUploadState: LoadingState }>()
);

export const markNotificationAsRead = action(
  ActionTypes.MARK_NOTIFICATION_AS_READ,
  payload<{ id: number }>()
);

export const setFolders = action(
  ActionTypes.SET_FOLDERS,
  payload<{ folders: File[] }>()
);

export const setParent = action(
  ActionTypes.SET_PARENT,
  payload<{ parent: File | undefined }>()
);

export const addToReadNotifications = action(
  ActionTypes.ADD_TO_READ_NOTIFICATIONS,
  payload<{ notification: Notification }>()
);

export const setNotification = action(
  ActionTypes.SET_NOTIFICATION,
  payload<{ notifications: Notification[] }>()
);

export const setNotificationAllowedState = action(
  ActionTypes.SET_NOTIFICATION_ALLOWED,
  payload<{ state: boolean }>()
);

export const setFavourite = action(
  ActionTypes.SET_FAVOURITES,
  payload<{ favourite: string }>()
);

export const setParentFavourite = action(ActionTypes.SET_PARENT_FAVOURITES);

export const setFavouritesRequestState = action(
  ActionTypes.SET_FAVOURITES_REQUEST_STATE,
  payload<{ favouritesLoadState: LoadingState }>()
);

export const setShareLink = action(
  ActionTypes.SET_SHARE_LINK,
  payload<{ shareLinks: string[] }>()
);

export const setShareLinkState = action(
  ActionTypes.SET_SHARE_LINK_STATE,
  payload<{ shareLinkState: LoadingState }>()
);

export const setCurrentSlide = action(
  ActionTypes.SET_CURRENT_SLIDE,
  payload<{ currentSlide: PptSlideShareRefs }>()
);

export const changeLanguage: ActionCreator<void> = (language: AppLanguages) => {
  localStorage.setItem(LocalStorageKeys.LANGUAGE, language);
  return (dispatch: Dispatch<Action>): void => {
    dispatch(setLanguage({ language }));
  };
};

export const fetchAccessToken: ActionCreator<ThunkAction<
  Promise<void>,
  State,
  any,
  any
>> = (code: string, successCb: () => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(setRequestState({ loadState: LoadingState.Loading }));
      const { data } = await getAccessToken(code);
      communication.webDavClientWrapper = webDavFactory(data);
      communication.axios = axiosFactory(data.access_token);
      set(TOKEN_COOKIE_NAME, JSON.stringify(data), {
        expires: TOKEN_COOKIE_EXPIRATION_IN_DAYS,
      });

      const userResponse = await userMe(data.user_id);
      const userData = userResponse.data.ocs.data;

      set(USER_COOKIE_NAME, JSON.stringify(userData), {
        expires: TOKEN_COOKIE_EXPIRATION_IN_DAYS,
      });

      dispatch(
        setUser({
          user: userData,
        })
      );
      successCb();
      dispatch(setRequestState({ loadState: LoadingState.Success }));
    } catch (err) {
      dispatch(setRequestState({ loadState: LoadingState.Failure }));
      dispatch(
        setSnack({
          snack: {
            message: i18nMark("Login not successful. Please try again."),
            severity: "error",
          },
        })
      );
      Sentry.captureException(err);
      // await logUserOut();
      // TODO uncomment
      // window.location.replace(redirectUrlForLogoutHref);
    }
  };
};

export const fetchRefreshToken: ActionCreator<ThunkAction<
  Promise<void>,
  State,
  any,
  any
>> = (code: string, successCb?: () => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(setRequestState({ loadState: LoadingState.Loading }));
      const { data } = await getAccessTokenFromRefresh(code);
      communication.webDavClientWrapper = webDavFactory(data);
      communication.axios = axiosFactory(data.access_token);
      set(TOKEN_COOKIE_NAME, JSON.stringify(data), {
        expires: TOKEN_COOKIE_EXPIRATION_IN_DAYS,
      });

      const userResponse = await userMe(data.user_id);
      const userData = userResponse.data.ocs.data;

      set(USER_COOKIE_NAME, JSON.stringify(userData), {
        expires: TOKEN_COOKIE_EXPIRATION_IN_DAYS,
      });

      dispatch(
        setUser({
          user: userData,
        })
      );

      if (successCb) {
        successCb();
      }

      setTimeout(() => {
        // @ts-ignore
        dispatch(fetchRefreshToken(data.refresh_token));
      }, Math.floor(data.expires_in * 0.9 * 1000));

      dispatch(setRequestState({ loadState: LoadingState.Success }));
    } catch (err) {
      dispatch(setRequestState({ loadState: LoadingState.Failure }));

      Sentry.captureException(err);
      window.location.href = redirectUrlForHref;
    }
  };
};

export const logOut: ActionCreator<ThunkAction<void, State, any, any>> = () => {
  return (dispatch: Dispatch<Action>): void => {
    communication.webDavClientWrapper = webDavFactory();
    communication.axios = axiosFactory();
    removeAllCookies();

    // await logUserOut();

    dispatch(
      setUser({
        user: undefined,
      })
    );

    window.location.replace(redirectUrlForLogoutHref);
  };
};

export const getNotifications: ActionCreator<ThunkAction<
  Promise<void>,
  State,
  any,
  any
>> = () => {
  return async (dispatch: Dispatch<Action>, getState): Promise<void> => {
    try {
      dispatch(
        setNotificationState({ notificationLoadState: LoadingState.Loading })
      );
      const response: NotifBase = await notifications();

      const notificationsStore = getState().general.notifications;
      const readNotificationsStore = getState().general.readNotifications;
      const notif = response.data?.ocs?.data || [];

      const notifNewerFiltered = notif.map((n) => parseNotificationLink(n));

      const mergedNotifications = [
        ...notifNewerFiltered,
        ...readNotificationsStore,
      ];
      const mappedNotification = mergedNotifications.map((n: Notification) => {
        const findNotificationById = notificationsStore.find(
          (no) => no.notification_id === n.notification_id
        );

        return {
          ...n,
          isRead: findNotificationById?.isRead || false,
        };
      });
      dispatch(setNotification({ notifications: mappedNotification }));
      dispatch(
        setNotificationState({ notificationLoadState: LoadingState.Success })
      );
    } catch (error) {
      // Sentry.captureException(error);
      dispatch(
        setSnack({
          snack: {
            message: i18nMark("Failed to fetch notifications"),
            severity: "error",
          },
        })
      );
      dispatch(
        setNotificationState({ notificationLoadState: LoadingState.Failure })
      );
    }
  };
};

export const markAsFavourite: ActionCreator<ThunkAction<
  Promise<void>,
  State,
  any,
  any
>> = (path: string, isFavourite: boolean) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(
        setFavouritesRequestState({ favouritesLoadState: LoadingState.Initial })
      );
      const isFav = isFavourite ? 0 : 1;
      const response = await postFavourite(path, isFav);
      if (response.status === 207 || response.status === 200) {
        dispatch(setFavourite({ favourite: path }));
        dispatch(setParentFavourite());
        dispatch(
          setFavouritesRequestState({
            favouritesLoadState: LoadingState.Success,
          })
        );
        dispatch(
          setSnack({
            snack: {
              message: i18nMark("Favorite status changed"),
              severity: "success",
            },
          })
        );
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(
        setSnack({
          snack: {
            message: i18nMark("Could not change favorites status"),
            severity: "error",
          },
        })
      );
      dispatch(
        setFavouritesRequestState({ favouritesLoadState: LoadingState.Failure })
      );
    }
  };
};

export const notificationRead: ActionCreator<ThunkAction<
  Promise<void>,
  State,
  any,
  any
>> = (notif: Notification) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(markNotificationAsRead({ id: notif.notification_id }));
      dispatch(addToReadNotifications({ notification: notif }));
      deleteNotification(notif.notification_id);
    } catch (error) {
      Sentry.captureException(error);
    }
  };
};

export const getCurrentSlide: ActionCreator<ThunkAction<
  Promise<void>,
  State,
  any,
  any
>> = (slide: PptSlideShareRefs) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch(setCurrentSlide({ currentSlide: slide }));
  };
};

export const setNotificationAllowed: ActionCreator<ThunkAction<
  Promise<void>,
  State,
  any,
  any
>> = (allowed: boolean) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(setNotificationAllowedState({ state: allowed }));
      localStorage.setItem(
        LOCALSTORAGE_NOTIFICATIONS_ALLOWED,
        allowed.toString()
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  };
};

export const getFolders: ActionCreator<ThunkAction<
  Promise<void>,
  State,
  any,
  any
>> = (path = "", onlyFavourites = false, depth = 1) => {
  return async (dispatch: Dispatch<Action>, getState): Promise<void> => {
    try {
      dispatch(setFolderFetchState({ folderLoadState: LoadingState.Loading }));
      const user = getState().general.loggedUser;
      const userName = getUsername(user);
      const extendedPath = `/files/${userName}${path}`;
      const wholePath =
        path[path.length - 1] === "/" ? extendedPath : `${extendedPath}/`;

      // 1. fetching folder in XML format using WebDav
      const { data } = !onlyFavourites
        ? await fetchFoldersXML(wholePath, depth)
        : await fetchFavourites(wholePath);
      // 2. converting XML to JSON
      const jsonData = xmlJs.xml2json(String(data), {
        compact: true,
        ignoreDoctype: true,
        attributesKey: "attributes",
        spaces: 4,
      });
      // 3. mapping JSON to desired FE object File[]
      const mappedFolders = mapJSONToFolderData(jsonData);

      const paths = mappedFolders.map((f) =>
        getDataPathFromFilename(f.filename)
      );

      const response = await fileInfoPost(paths, true);

      const filesInfo = response.data.length
        ? response.data.filter((f: FileInfo) => !f.error)
        : [];

      const folderMapped = await Promise.all(
        mappedFolders
          .filter((f) => !isPrivateFile(f))
          .map((f) => adjustFileWithMetaData(f, filesInfo))
      );

      const { parent, folders } = separateParent(folderMapped, wholePath);

      dispatch(setParent({ parent }));
      dispatch(setFolders({ folders }));
      dispatch(setFolderFetchState({ folderLoadState: LoadingState.Success }));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(
        setSnack({
          snack: {
            message: i18nMark("Error while fetching files"),
            severity: "error",
          },
        })
      );
      dispatch(setFolderFetchState({ folderLoadState: LoadingState.Failure }));
    }
  };
};

export const getShareLink: ActionCreator<ThunkAction<
  Promise<void>,
  State,
  any,
  any
>> = (filenames: string[]) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(setShareLinkState({ shareLinkState: LoadingState.Loading }));

      const links = await getShareFileLink(filenames);
      if (links) {
        const newLinks = links.map((item) => {
          const { origin: linkUrl } = new URL(item);

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return item.replace(linkUrl, process.env.REACT_APP_RSAM!);
        });

        dispatch(setShareLink({ shareLinks: newLinks }));
      }

      dispatch(setShareLinkState({ shareLinkState: LoadingState.Success }));
    } catch (error) {
      dispatch(
        setSnack({
          snack: {
            message: i18nMark("Could not generate share link"),
            severity: "error",
          },
        })
      );
      Sentry.captureException(error);
      dispatch(setShareLinkState({ shareLinkState: LoadingState.Failure }));
    }
  };
};

export const uploadJSONFile: ActionCreator<ThunkAction<
  Promise<void>,
  State,
  any,
  any
>> = (filename: string, file: string) => {
  return async (dispatch: Dispatch<Action>, getState): Promise<void> => {
    try {
      dispatch(setFileUploadState({ fileUploadState: LoadingState.Loading }));
      const user = getState().general.loggedUser;
      const userName = getUsername(user);
      const path = `/files/${userName}/${filename}.${noteExt}`;

      const response = await postFileByWebDav(path, file);
      if (response) {
        const parsed = JSON.parse(file) as Note;
        dispatch(setNote({ note: parsed }));
        dispatch(
          setFileUploadState({
            fileUploadState: LoadingState.Success,
          })
        );
        dispatch(
          setSnack({
            snack: {
              message: i18nMark("The note was saved to My Files"),
              severity: "success",
            },
          })
        );
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(
        setSnack({
          snack: {
            message: i18nMark("Could not save the note"),
            severity: "error",
          },
        })
      );
      dispatch(setFileUploadState({ fileUploadState: LoadingState.Failure }));
    }
  };
};

export const fetchNote: ActionCreator<ThunkAction<
  Promise<void>,
  State,
  any,
  any
>> = (file: File, successCb: () => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(setFileUploadState({ fileUploadState: LoadingState.Loading }));

      const note = await fetchNoteContent(file);
      if (note) {
        dispatch(setNote({ note }));
        dispatch(
          setFileUploadState({
            fileUploadState: LoadingState.Success,
          })
        );
        successCb();
      }
    } catch (error) {
      dispatch(
        setSnack({
          snack: {
            message: i18nMark("Failed to fetch a note"),
            severity: "error",
          },
        })
      );
      Sentry.captureException(error);
      dispatch(setFileUploadState({ fileUploadState: LoadingState.Failure }));
    }
  };
};

export const handleDownloadFile: ActionCreator<ThunkAction<
  Promise<void>,
  State,
  any,
  any
>> = (path: string, basename: string) => {
  return async (dispatch: Dispatch<Action>, getState): Promise<void> => {
    try {
      dispatch(setDownloadFileState({ loadState: LoadingState.Loading }));
      const user = getState().general.loggedUser;
      const userName = getUsername(user);
      const extendedPath = path.includes("/files/")
        ? path
        : `/files/${userName}${path}`;

      const url = await createDownloadUrl(extendedPath);
      const link = document.createElement("a");
      link.href = url;
      link.download = basename;
      document.body.appendChild(link);
      link.click();
      dispatch(setDownloadFileState({ loadState: LoadingState.Success }));
    } catch (error) {
      dispatch(
        setSnack({
          snack: {
            message: i18nMark("Failed to download file"),
            severity: "error",
          },
        })
      );
      Sentry.captureException(error);
      dispatch(setDownloadFileState({ loadState: LoadingState.Failure }));
    }
  };
};
